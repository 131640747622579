import React, { useEffect, useState, useRef, useContext, Suspense, lazy } from "react";
import GridTable from '@nadavshaar/react-grid-table';
import { useTranslation } from 'react-i18next';
import { UserContext } from "../userDetails";
import { parseISO } from "date-fns";

import "./RidesView.css"
import { AiOutlineSearch } from 'react-icons/ai'

import { ridesColumns, getLabel, columnsRidesView, getCallRender, getHeaderCellRenderer, searchNFD } from "./ridesColumns"; //ColumnManager, updateColumnsLayout
import { ridesRows, checkRows, parseIdsRides, information, Loader } from "./ridesRows";
import { DatePickerComponentFull } from "../DatePickerComponent/DatePickerComponent"
import { newDate, thisWeek } from "../DatePickerComponent/DateSelect";
import { SelectComponentDevices, FilterComponentDrivers, FilterComponentPurposes } from "../SelectComponent/SelectComponent"
import { formatDateObjectTime } from "../../components/DatePickerComponent/DatePickerConfig"
import RidesEdit from "./RidesEdit";
import Loading from "../Loading";
import { ColumnManager, updateColumnsLayout } from "../ColumnManager";
// import { Ign } from "../../images";
// import { Carousel } from "react-bootstrap";

const Excel = lazy(() => import('../../components/export').then(module => ({ default: module.Excel })));

export function checkRole(roles, type, dev) {
    let res = false;
    const role = roles.filter(r => r.type.symbol === type)
    // console.log(dev);
    role.forEach(r => {
        if (r.type.tablename === 'device' && dev && (r.refid === dev.id || r.refid === dev)) return res = true
        if (r.type.tablename === 'client') return res = true
    })
    return res;
}

export default function RidesView({ setRidesIds, setRidesIdsFocus, ridesIds, ridesDaily, devices, devFocus, drivers, drivFocus, setDrivFocus, WsSend, setDevFocus, height, width, max, rides, stats, date, setDate, editRides, setEditRides, prevDevFocus, loading, setLoading }) {
    const { t } = useTranslation('translation');
    const { uiConfig, setUiConfig, costumer } = useContext(UserContext);
    const clientRoles = useContext(UserContext).clientRoles;
    const eR = useContext(UserContext).uiConfig.editorRides;
    const sc = useContext(UserContext).uiConfig.columnsRidesView;

    const [rows, setRows] = useState([]);
    const [rowsopen, setRowsOpen] = useState([]);
    const [searchAll, setSearchAll] = useState(false);
    const [rowstemp, setrowstemp] = useState();
    const [column, setColumn] = useState();
    const [rowsSelected, setRowsSelected] = useState([]);
    const [statsRowsSelected, setStatsRowsSelected] = useState({});
    const [enableRidesEditor, setEnableRidesEditor] = useState(eR);
    const [btncolumnman, setBtncolumnman] = useState(false);
    const [nullDevice, setNullDevice] = useState(false);
    const [filterPurpose, setFilterPurpose] = useState(false);
    // const loading = useRef(true);
    // const [date, setDate] = useState(false)
    const [isAscending, seIsAscending] = useState(
        localStorage.getItem("rides-isAscending") ? JSON.parse(localStorage.getItem("rides-isAscending")) : { colId: null, isAsc: false }
    );
    const refRender = useRef(null);
    const refWS6 = useRef(null);
    const refWS16 = useRef(null);
    const purpose_array = useRef();
    const hideColumns = useRef([]);
    const hideColumnsRun = useRef(true);
    const waitingSendMask = useRef({ time: false, first: true });
    const reactGridRef = useRef(null);

    // const c = ridesColumns(setrowstemp, headerDriver, uiConfig);

    const Information = (tab) => information(tab, stats, statsRowsSelected)
    // const ColumnVisibility = (tab) => ColumnManager(tab, emptyColumnsArr, setEnableRidesEditor, enableRidesEditor, checkRole(clientRoles, 'edit_events', devFocus), lock)

    const ColumnVisibility = ({ tableManager }) => {
        return ColumnManager(tableManager, btncolumnman, setBtncolumnman, 'ridesView', null, hideColumns.current, devFocus, setrowstemp, hideColumnsRun, setEnableRidesEditor, enableRidesEditor)
    }

    // useEffect(() => {
    //     setUiConfig({ ...uiConfig, ridesColumnCenter: true })
    // }, []) 

    useEffect(() => {
        if (!column) {
            setColumn(getColumns(sc ? sc : columnsRidesView(setrowstemp)))
        }
    }, [column])

    useEffect(() => {
        setColumn(getColumns(sc ? sc : columnsRidesView(setrowstemp)))
    }, [drivFocus, nullDevice])

    if (drivers && drivers === drivFocus && !refWS6.current && !ridesDaily && date.length > 1) {
        // if user privacy for driver
        const params = {
            method: 'getRides',
            oid: 6,
            stampFrom: new Date(date[0]),
            stampTo: new Date(date[1]),
            pageSize: 5000,
        }
        WsSend(params);
        refWS6.current = true;
    } else if (!refWS6.current && !ridesDaily && devFocus && date.length > 1) {
        const params = {
            method: 'getRides',
            oid: 6,
            stampFrom: new Date(date[0]),
            stampTo: new Date(date[1]),
            pageSize: 5000,
            device: devFocus.id
        }
        WsSend(params);
        refWS6.current = true;
        // setRides([]);
        // setRidesDaily([]);
    }

    if (refWS6.current && !refWS16.current && !drivers) {
        WsSend({ method: 'getDrivers', oid: 16, ddd: false });
        refWS16.current = true;
    }

    useEffect(() => {
        if (drivFocus && refWS6.current) {
            setDevFocus(null)
            setLoading(true);
            WsSend({
                method: 'modify',
                id: 6,
                params: {
                    driver: drivFocus.value,
                    device: null,
                }
            });
        }
        if (drivFocus === null && refWS6.current) {
            const device = devices.find((d) => d.id == localStorage.getItem('devFocus'))
            let deviceTemp = devices[0];
            if (device) deviceTemp = device;
            setDevFocus(deviceTemp);
            setLoading(true);
            WsSend({
                method: 'modify',
                id: 6,
                params: {
                    driver: null,
                    device: deviceTemp.id
                }
            })
        }
    }, [drivFocus])

    useEffect(() => {
        if (filterPurpose && refWS6.current) {
            WsSend({
                method: 'modify',
                id: 6,
                param: 'purpose',
                value: filterPurpose.value
            });
        }
        else {
            WsSend({
                method: 'modify',
                id: 6,
                param: 'purpose',
                value: null
            });
        }
    }, [filterPurpose])

    useEffect(() => {
        if (ridesDaily) {
            const rows = ridesRows(ridesDaily, rowsopen, searchAll, isAscending.colId == 0 ? isAscending.isAsc : false, drivFocus || nullDevice ? true : false);
            setRows(rows);

            // hide empty columns
            if (column) {
                hideColumnsRun.current = false;
                hideColumns.current = column.reduce((acc, data) => {
                    if (data.field === 'driver_purpose' && rows.every(i => i.driver_purpose.split(';')[0].length === 0 && i.driver_purpose.split(';')[1].length === 0)) acc.push(data);
                    if (data.field === 'fuel' && rows.every(i => i.fuel.startsWith('0'))) acc.push(data);
                    if (data.field === 'type' && rows.every(i => i.type === t('stats.bussines'))) acc.push(data);
                    return acc
                }, []);
            }
            setLoading(false)
        }
    }, [ridesDaily, searchAll, isAscending])

    useEffect(() => {
        setRows(ridesRows(ridesDaily, rowsopen, searchAll, isAscending.colId == 0 ? isAscending.isAsc : false, drivFocus || nullDevice ? true : false));
    }, [rowsopen])

    useEffect(() => {
        if (rowstemp) {
            if (rowstemp.id.length > 1) {
                const date = rowstemp.stamp.split("T")[0];
                if (rowsopen.length === 0) setRowsOpen([date]);
                else {
                    if (!rowsopen.includes(date)) setRowsOpen([...rowsopen, date]);
                    else setRowsOpen(rowsopen.filter(rowId => rowId !== date))
                }
            }

        }
    }, [rowstemp])


    useEffect(() => {
        if (!date && sessionStorage.getItem('rides.date')) setDate(newDate(JSON.parse(sessionStorage.getItem('rides.date'))))
        else if (!date) {
            const firstDate = thisWeek();
            if (firstDate.length > 1) {
                setDate(firstDate)
            }
        }
        if (date.length > 1) {
            if (refRender.current && refWS6.current) {
                setLoading(true);
                WsSend({
                    method: 'modify',
                    id: 6,
                    params: {
                        // device: devFocus.id,
                        stampFrom: new Date(date[0]),
                        stampTo: new Date(date[1]),
                        pageSize: 5000
                    }
                });
                sessionStorage.setItem('rides.date', JSON.stringify([date[0].format(formatDateObjectTime), date[1].format(formatDateObjectTime)]))
            }
            //skip first render where set first date
            refRender.current = true;
        }
    }, [date])

    useEffect(() => {
        setRowsOpen([])
    }, [devFocus])

    useEffect(() => {
        if (enableRidesEditor !== eR) {
            setTimeout(() => {
                setUiConfig({ ...uiConfig, editorRides: enableRidesEditor })
            }, 5);

        }
    }, [enableRidesEditor])

    useEffect(() => {
        if (ridesIds.length === 0 && rowsSelected.length > 0) {
            setRowsSelected([])
        }
    }, [ridesIds])

    useEffect(() => {
        if (rowsSelected.length > 0) {
            let distance = 0;
            let duration = 0;
            rowsSelected.forEach(r => {
                if (!r.includes(',')) {
                    const ride = rides.find(rid => rid.id === parseInt(r));
                    distance = distance + ride.distance;
                    duration = duration + (parseISO(ride.stamp2) - parseISO(ride.stamp1));
                }
            })
            setStatsRowsSelected({distance: distance, duration: duration})
        }
    }, [rowsSelected])

    const getColumns = (c) => {
        const pA = c.find(i => i.field === 'purpose_array');
        if (pA && pA.visible) purpose_array.current = true;
        return c.map(data => {
            let res = {
                ...data,
                visible: c[data.index].visible,
                width: c[data.index].width,
                label: getLabel(data.field, drivFocus || nullDevice ? true : false, pA.visible),
                // headerCellRenderer: headerWithTitle
            }
            if (data.field === 'fuel') res.className = 'text-end';
            const callrender = getCallRender(data.field, setrowstemp, pA.visible, drivFocus || nullDevice ? true : false);
            const headerCellRenderer = getHeaderCellRenderer(data.field);
            // //   const getsortf = getSortF(data.field);
            if (callrender) res = { ...res, cellRenderer: callrender }
            if (headerCellRenderer) res = { ...res, headerCellRenderer: headerCellRenderer }
            if (data.field === 'text') res = { ...res, search: searchNFD }
            // //   if (getsortf) res = { ...res, sort: getsortf }
            return res
        })
    }

    if (!ridesDaily && !date) return <></>
    else {
        if (reactGridRef.current) {
            const weekend = reactGridRef.current.querySelectorAll('.weekend');
            if (weekend) {
                weekend.forEach(w => {
                    const dataRowIndex = w.parentElement.getAttribute('data-row-index');
                    const elements = reactGridRef.current.querySelectorAll(`[data-row-index="${dataRowIndex}"]`);
                    elements.forEach(element => {
                        element.classList.add('weekend-highlighted');
                    });
                })

            }
        }
        return (
            <div style={{ height: height + 'px' }}>
                <div className="d-flex">
                    <SelectComponentDevices
                        devices={devices}
                        devFocus={devFocus}
                        setDevFocus={setDevFocus}
                        WsSend={WsSend}
                        height={height}
                        wsId={6}
                        drivFocus={drivFocus}
                        prevDevFocus={prevDevFocus}
                        setLoading={setLoading}
                        search={searchAll}
                        setNullDevice={setNullDevice}
                        nullDevice={nullDevice}
                        filterPurpose={filterPurpose}
                    />
                    <DatePickerComponentFull
                        date={date}
                        setDate={setDate}
                        w={width}
                        h={height}
                        m={max}
                    />
                    <FilterComponentDrivers
                        drivers={drivers}
                        filterDrivers={drivFocus}
                        setFiletrDrivers={setDrivFocus}
                        height={height}
                    />
                    <FilterComponentPurposes
                        purposes={stats.purpose}
                        filterPurpose={filterPurpose}
                        setFilterPurpose={setFilterPurpose}
                        height={height}
                        purpose_array={purpose_array.current}
                    />
                    <Suspense>
                        <Excel
                            excelData={{ type1: ridesRows(ridesDaily, rowsopen, searchAll, isAscending.colId == 0 ? isAscending.isAsc : false, drivFocus || nullDevice ? true : false, column, hideColumns.current), type2: ridesRows(ridesDaily, rowsopen, true, isAscending.colId == 0 ? isAscending.isAsc : false, drivFocus || nullDevice ? true : false, column, hideColumns.current), stats: stats }}
                            fileName={'rides'}
                            devFocus={devFocus}
                            drivFocus={drivFocus}
                            date={date}
                            clientName={costumer}
                        />
                    </Suspense>

                    {checkRole(clientRoles, 'edit_events', devFocus) && enableRidesEditor ?
                        <RidesEdit
                            purposes={stats.purpose}
                            ids={ridesIds}
                            WsSend={WsSend}
                            editRides={editRides}
                            setEditRides={setEditRides}
                            drivers={drivers}
                            w={width}
                            m={max}
                            columns={getColumns(sc ? sc : columnsRidesView(setrowstemp))}
                            setColumn={setColumn}
                        /> : ''}
                </div>
                {!loading ? (<div ref={reactGridRef}>
                    <GridTable
                        style={{ minHeight: 'unset', height: (height - 70) + 'px' }} //(width < (enableRidesEditor ? 500 : 370) ? (max ? 70 : 140) : 70))
                        // columns={c}
                        columns={column}
                        pageSize={5000}
                        rows={rows}
                        showSearch={true}
                        showColumnVisibilityManager={true}
                        showRowsInformation={searchAll ? false : (width > 500 ? true : (max ? true : false))}
                        isPaginated={false}
                        texts={{
                            search: '',
                            noResults: ridesDaily && ridesDaily.length === 0 ? t('devices.notFind') : t('login.loading')
                        }}
                        className="RidesView"
                        icons={{ search: <AiOutlineSearch /> }}
                        onColumnReorderEnd={(column, tableManager) => { updateColumnsLayout(tableManager, uiConfig, setUiConfig, 'ridesView', hideColumns.current) }}
                        onColumnResizeEnd={({ column }, tableManager) => { updateColumnsLayout(tableManager, uiConfig, setUiConfig, 'ridesView', hideColumns.current) }}
                        onSortChange={({ colId, isAsc }) => {
                            seIsAscending({ colId: colId, isAsc: isAsc })
                            localStorage.setItem("rides-isAscending", JSON.stringify({ colId: colId, isAsc: isAsc }))
                        }}
                        components={{ ColumnVisibility, Information, Loader }}
                        enableColumnsReorder={true}
                        sort={isAscending}
                        onSearchTextChange={(searchText) => {
                            if (searchText && searchText.length > 0) {
                                if (nullDevice && searchText.length > 2) {
                                    if (waitingSendMask.current.time) {
                                        clearTimeout(waitingSendMask.current.time);
                                    }
                                    waitingSendMask.current.time = setTimeout(() => {
                                        if (!waitingSendMask.current.first) {
                                            WsSend({
                                                method: 'modify',
                                                id: 6,
                                                param: 'mask',
                                                value: searchText
                                            })
                                        }
                                        waitingSendMask.current.first = false;
                                    }, 400)
                                }
                                setSearchAll(searchText);
                            }
                            else {
                                if (nullDevice && !filterPurpose) {
                                    const device = devices.find((d) => d.id == localStorage.getItem('devFocus'))
                                    let deviceTemp = devices[0];
                                    if (device) deviceTemp = device;
                                    if (!drivFocus) setDevFocus(deviceTemp);
                                    setLoading(true);
                                    setNullDevice(false);
                                    WsSend({
                                        method: 'modify',
                                        id: 6,
                                        params: {
                                            mask: null,
                                            device: drivFocus && !devFocus ? null : deviceTemp.id,
                                            driver: drivFocus ? drivFocus.value : null
                                        }
                                    })
                                }
                                //  else if (searchAll) {
                                //     WsSend({
                                //         method: 'modify',
                                //         id: 6,
                                //         param: 'mask',
                                //         value: null
                                //     })
                                // }

                                else setSearchAll(false);
                            }
                            sessionStorage.setItem("rides-search", searchText)
                        }}
                        onRowClick={({ data, column }, tableManager) => {
                            if (column.id !== 0) {
                                setTimeout(() => {
                                    tableManager.refs.tableRef.current.querySelectorAll('.rideFocus').forEach(i => i.classList.remove('rideFocus'))
                                    tableManager.refs.tableRef.current.querySelectorAll(`[data-row-id="${data.id}"]`).forEach(i => i.classList.add('rideFocus'))
                                }, 100);
                                let selectedRowsIds = data.id.split(',');
                                rowsSelected.forEach(i => {
                                    if (!selectedRowsIds.includes(i)) selectedRowsIds.push(i)
                                })
                                if (data.id.split(',').length > 1) selectedRowsIds.push(data.id);
                                const rows = checkRows(selectedRowsIds, rowsSelected, null);
                                setRidesIds(parseIdsRides(rows));
                                setRowsSelected(rows);
                                setRidesIdsFocus(parseIdsRides(data.id.split(',')))
                            }
                        }}
                        selectedRowsIds={rowsSelected}
                        onSelectedRowsChange={(selectedRowsIds, tableManager) => {
                            setRidesIdsFocus([])
                            tableManager.refs.tableRef.current.querySelectorAll('.rideFocus').forEach(i => i.classList.remove('rideFocus'))
                            const rows = checkRows(selectedRowsIds, rowsSelected, tableManager.rowSelectionApi.selectAll.ref.current);
                            setRidesIds(parseIdsRides(rows))
                            setRowsSelected(rows);
                        }}
                        isLoading={loading}
                    />
                </div>) : (<Loading classList='mt-5' color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} height={height - 100} />)}

            </div>
        )
    }
}