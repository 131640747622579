import React, { useState, useContext, useEffect, useRef } from "react";
import { DatePickerComponent, DatePickerComponentFull } from "../../components/DatePickerComponent/DatePickerComponent"
import { thisWeek } from "../../components/DatePickerComponent/DateSelect";
import { client } from 'websocket';
import { Form, Button, Table, OverlayTrigger, Tooltip, Spinner, Nav } from "react-bootstrap";
import { addSeconds, addDays, addHours, format, startOfDay, endOfDay, addMonths, subMonths, endOfMonth, parseISO, isWithinInterval, differenceInHours, min, max, isEqual, subDays, formatDistance as formatDistanceFns, getDay } from 'date-fns';
import { DateObject } from "react-multi-date-picker";
import { ToastContainer, toast, Slide } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { BsPencilSquare } from "react-icons/bs";
import { FaCheckCircle, FaRegCalendarCheck, FaRegCalendarAlt } from "react-icons/fa";
import { FcRefresh } from "react-icons/fc";
import { TiDelete } from "react-icons/ti";
import { FaCarSide } from "react-icons/fa6";
import { CgNotes } from "react-icons/cg";
import { AiOutlineSearch } from 'react-icons/ai'
import { useNavigate, useLocation } from "react-router-dom";
import GridTable from '@nadavshaar/react-grid-table';
import { iconKind } from "../../images/kind"
import { LogoAutopujcovna } from "../../images";

import { SelectComponentDevices, SelectComponentDrivers } from "../../components/SelectComponent/SelectComponent"
import Loading from "../../components/Loading";
import { WsSend, WsAnal, WSdata, WSdataList, downloadFromContent } from "../../scripts/ws";
import { formatDistance, Loader } from "../../components/RidesView/ridesRows"
import { UserContext, getQuery, clearQuery, useWindowSize } from "../../components";

import './CarRental.css'

export default function CarRental() {
    const [dateFrom, setDateFrom] = useState(new DateObject(addHours(new Date().setMinutes(0, 0, 0), 1)))
    const [dateTo, setDateTo] = useState(new DateObject(addHours(new Date().setMinutes(0, 0, 0), 2)))
    const [devices, setDevices] = useState(false) //devices filter via group car rent
    const [devicesAvail, setDevicesAvail] = useState([]) // devices filter via available
    const [devFocus, setDevFocus] = useState(false);
    const [drivers, setDrivers] = useState(false)
    const [driversChoose, setDriversChoose] = useState(false)
    const [loadingPage, setLoadingPage] = useState(true);
    const [rangeDays, setRangeDays] = useState(false);
    const [pool, setPool] = useState(false);
    const [edit, setEdit] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(null);
    const [adminPool, setAdminPool] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [exportPdf, setExportPdf] = useState(false);
    const [editService, setEditService] = useState(false);
    const [tabs, setTabs] = useState('carRental');
    const [date, setDate] = useState(thisWeek())
    const [poolWaiting, setPoolWaiting] = useState(false);
    const [log, setLog] = useState(false);
    const [swop, setSwop] = useState([]);
    const [selectedTime, setSelectedTime] = useState({});

    const refWS16 = useRef(null);
    const refWS27 = useRef(null);
    const formData = useRef(null);
    const tableContainerRef = useRef(null);
    const firstRender = useRef(true);
    const newReservation = useRef(false);
    const { user, uiConfig, clientRoles } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();
    const exportPdfID = useRef(null);
    const mainRemarks = useRef();
    const heightWindow = useWindowSize().height;
    const [showReservation, setShowReservation] = useState(false);

    if (!pool && !refWS27.current && rangeDays) {
        WsSend({
            method: 'getPool',
            oid: 27,
            stampFrom: rangeDays[0],
            stampTo: rangeDays[rangeDays.length - 1],
            available: true
        });
        // console.log(rangeDays[rangeDays.length - 1]);
        refWS27.current = true;
    }

    if (!refWS16.current && !drivers) {
        WsSend({ method: 'getDrivers', oid: 16, ddd: null });
        refWS16.current = true;
    }

    client.onmessage = (message) => {
        let data = WsAnal(message)
        console.log('new data CarRental', data);
        // update message
        if (data && data.length > 0) {
            if (data.some(d => d.object === 27)) {
                const res = WSdataList(data, pool, 27);
                setPool(res)
                console.log(res);
                if (tabs === 'waitAthorized') setPoolWaiting(res)
                if (loadingPage) setLoadingPage(false)
            }
            if (data.some(d => d.object === 16)) setDrivers(WSdata(data, drivers, 16));
        }
        // first new message from query
        else {
            if (data && data.id === 27) {
                const res = WSdata(data, pool, 27);
                setPool(res);
                if ((res.aux.authorizers.some(u => u === user.id) || clientRoles.some(i => i.type.symbol === 'data_curator')) && res.aux.devicesAdmin[0]) setDevices(res.aux.devices.concat(res.aux.devicesAdmin))
                else setDevices(res.aux.devices)
                setLoadingPage(false)
                console.log(res);
            }
            else if (data && data.oid === 27) {
                if (loadingPage) setLoadingPage(false)
                if (data.value) {
                    if (data.value !== true) {
                        exportPdfID.current = data.value;
                        setExportPdf(data.value)
                        setSelectedTime({})
                    }
                    setTimeout(() => {
                        toast.success('Úspešně uloženo')
                        if (adminPool) setShowReservation(false);
                    }, 200);
                }
                else {
                    setTimeout(() => {
                        toast.error('Při ukládání došlo k chybě')
                    }, 200);
                }
            }
            else if (data && data.id === 16) {
                const res = WSdata(data, drivers, 16).list;
                setDrivers(res);
            }
            else if (data && data.oid === 29) {
                if (data.value && exportPdf === 'loading') {
                    downloadFromContent(data.value, `zadanka_${exportPdf}.pdf`, true)
                    setExportPdf(exportPdfID.current);
                    if (loadingPage) setLoadingPage(false);
                }
                else if (tabs === 'log') {
                    setLog(data.value)
                    if (loadingPage) setLoadingPage(false)
                }
            }
            else if (data && data.oid === 30 && data.value) setDriversChoose({ driver: { ...driversChoose.driver, value: parseInt(data.value) } });
        }
        setTimeout(() => {
            if (loadingPage) setLoadingPage(false)
        }, 3000);
    }
    const handleExternalSubmit = () => {
        if (formData.current) {
            if (formData.current.checkValidity()) {
                formData.current.requestSubmit();
            } else {
                formData.current.reportValidity();
            }
        }
    };

    const handleEditReservation = (pool) => {
        setShowReservation(true)
        setOpenTooltip(null)
        setEdit(pool)
        setDateFrom(new DateObject(parseISO(pool.stamp)))
        setDateTo(new DateObject(parseISO(pool.laststamp)))
        setDevFocus(devices.filter(d => d.id === pool.device)[0])
        setSelectedTime({ dateFrom: format(parseISO(pool.stamp), 'HH:mm'), dateTo: format(parseISO(pool.laststamp), 'HH:mm') })
        setTimeout(() => {
            formData.current.querySelector('input[name="crews"]').value = pool.crews ? pool.crews : ''
            formData.current.querySelector('input[name="pickups"]').value = pool.pickups ? pool.pickups : ''
            formData.current.querySelector('input[name="loads"]').value = pool.loads ? pool.loads : ''
            formData.current.querySelector('input[name="dropoffs"]').value = pool.dropoffs ? pool.dropoffs : ''
            formData.current.querySelector('input[name="contacts"]').value = pool.contacts ? pool.contacts : ''
            formData.current.querySelector('input[name="reason"]').value = pool.reason ? pool.reason : ''
            formData.current.querySelector('input[name="cctr"]').value = pool.cctr ? pool.cctr : ''
            formData.current.querySelector('textarea[name="remarks"]').value = pool.remarks ? pool.remarks : ''
            if (adminPool) {
                formData.current.querySelector('textarea[name="iremarks"]').value = pool.iremarks ? pool.iremarks : ''
                formData.current.querySelector('input[name="owner"]').value = pool.oname ? pool.oname : ''
            }
        }, 100);

        // const inputElement = inputRef.current;
        // if (inputElement) {
        //     inputElement.value = formData.get('inputName');
        // }

        // setModalOpen(checkAvalibility(dev, day, true))
    }

    const handleConfirmReservation = (p, s) => {
        setOpenTooltip(null)
        setModalOpen(false)
        const data = {
            method: 'setPool',
            oid: 27,
            id: p.id,
            status: s,
            isauthorized: true
        }
        if (p.iremarks) data.iremarks = p.iremarks;
        WsSend(data);
    }

    const sendReservation = () => {
        const form = new FormData(formData.current)
        const data = {
            method: 'setPool',
            oid: 27,
            stamp: dateFrom.toDate(),
            laststamp: dateTo.toDate(),
            poolg: pool.aux.id,
            authorizers: pool.aux.authorizers,
            crew: [driversChoose.driver.value],
            device: devFocus.id
        }
        if (form.get('crews')) data.crews = form.get('crews')
        if (form.get('pickups')) data.pickups = form.get('pickups')
        if (form.get('loads')) data.loads = form.get('loads')
        if (form.get('dropoffs')) data.dropoffs = form.get('dropoffs')
        if (form.get('contacts')) data.contacts = form.get('contacts')
        if (form.get('reason')) data.reason = form.get('reason')
        if (form.get('cctr')) data.cctr = form.get('cctr')
        if (form.get('remarks')) data.remarks = form.get('remarks')
        if (form.get('iremarks')) data.iremarks = form.get('iremarks')
        if (adminPool && !edit) {
            data.status = 2
            data.isauthorized = true
        }
        if (edit) {
            let changeSome = driversChoose.driver.value !== edit.crew[0];
            if (!changeSome) changeSome = !isEqual(data.stamp, parseISO(edit.stamp))
            if (!changeSome) changeSome = !isEqual(data.laststamp, parseISO(edit.laststamp))
            if (!changeSome) {
                Object.entries(data).forEach(([key, value], i) => {
                    if (i > 6) {
                        if (i, edit[key] !== value) changeSome = true;
                    }
                })
            };
            if (!changeSome) {
                setTimeout(() => {
                    toast.warning('Žádné změny v rezervaci!')
                }, 200);
            }
            else {
                data.id = edit.id;
                if (!adminPool) {
                    data.isauthorized = false;
                    data.status = 0
                }
                setLoadingPage(true);
                WsSend(data)
                setEdit(false)

            }
        } else {
            setLoadingPage(true);
            WsSend(data)
        }
    }


    const getDaysInRange = (start, end) => {
        const days = [];
        let currentDate = start;
        while (currentDate <= end) {
            days.push(currentDate);
            currentDate = addDays(currentDate, 1);
        }
        return days;
    };

    const handleDownloadPDF = (id) => {
        WsSend({
            method: 'getExportNode',
            oid: 29,
            type: 'carRental',
            download: 'true',
            device: id
        });
        setExportPdf('loading');
    }

    const handleMaintenance = () => {
        WsSend({
            method: 'setPool',
            oid: 27,
            stamp: startOfDay(dateFrom.toDate()),
            laststamp: endOfDay(dateTo.toDate()),
            poolg: pool.aux.id,
            status: 1,
            isauthorized: true,
            id: editService ? editService.id : null,
            authorizers: pool.aux.authorizers,
            // crew: [driversChoose.driver.value],
            iremarks: mainRemarks.current.value,
            device: devFocus.id
        })
        setEditService(false);
        setDevFocus(null);
        setSelectedTime({});
    }


    if (!rangeDays) {
        setRangeDays(getDaysInRange(startOfDay(subDays(new Date(), 1)), endOfMonth(addMonths(new Date(), 1))))
    }

    const handleScrollToElement = (scrollTo, metod) => {
        if (tableContainerRef.current) {
            const targetElement = tableContainerRef.current.querySelector(`.${scrollTo}`);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth', inline: metod ? metod : 'nearest' });
            }
        }
    };


    const checkAvalibility = (dev, day, getInfo) => {
        const poolDev = pool.list.filter(p => p.device === dev.id);
        const isOddMonth = day.getMonth() % 2 !== 0;
        const lastDayWeek = getDay(day) === 0;
        const choose = devFocus && devFocus.id === dev.id && (day >= startOfDay(dateFrom.toDate()) && day <= endOfDay(dateTo.toDate()));
        if (poolDev.length > 0) {
            const poolDevList = poolDev.map(p => {
                const startStamp = parseISO(p.stamp);
                const endStamp = parseISO(p.laststamp);
                if (isWithinInterval(day, { start: startOfDay(startStamp), end: endOfDay(endStamp) })) {
                    const actualStart = max([startOfDay(day), startStamp]);
                    const actualEnd = min([endOfDay(day), endStamp]);
                    const hoursInInterval = differenceInHours(actualEnd, actualStart) + 1;
                    const maintenance = p.status === 1;
                    if (getInfo) {
                        if (!maintenance) return { start: format(actualStart, 'HH:mm'), end: format(actualEnd, 'HH:mm'), pool: p }
                        else return { maintenance: true, pool: p }
                    }
                    if (hoursInInterval === 24) return `${maintenance ? (adminPool ? 'editable ' : '') : 'bookFull '}${lastDayWeek ? 'lastDay ' : ''}${isOddMonth ? 'odd' : ''}${p.isauthorized ? '' : ' reservation'}${choose ? ' choose' : ''}${p.iremarks && adminPool && !maintenance ? ' iremarks' : ''}${maintenance ? ' service' : ''}`;
                    else return `book ${p.iremarks && adminPool ? 'iremarks ' : ''}${lastDayWeek ? 'lastDay ' : ''}${isOddMonth ? 'odd ' : ''}${p.isauthorized ? '' : 'reservation '}${choose ? 'choose ' : ''}`

                }
                else return `free${isOddMonth ? ' odd' : ''}${lastDayWeek ? ' lastDay' : ''}${choose ? ' choose' : ''}`;

            }).filter(p => p !== undefined);
            if (getInfo) {
                const res = poolDevList.filter(p => p.pool !== undefined)
                if (res.length > 0) return res
                else return [poolDevList[0]]
            }
            if (poolDevList.length > 1) {
                const res = poolDevList.filter(p => p !== 'free' && p !== 'free odd' && p !== 'free choose' && p !== 'free lastDay' && p !== 'free odd choose' && p !== 'free odd lastDay');
                if (res.length > 0) {
                    if (res.length > 1 && adminPool) return (`${res}doubleReservation`)
                    return res
                }
                else return poolDevList[0]
            }
            else return poolDevList
        }
        else return [`free${isOddMonth ? ' odd' : ''}${lastDayWeek ? ' lastDay' : ''}${choose ? ' choose' : ''}`]

    }

    useEffect(() => {
        if (edit && editService) setEditService(false);
        if (dateTo < dateFrom) setDateTo(dateFrom); //.add(2, "hours")
        if (rangeDays && addDays(dateTo.toDate(), 1) > rangeDays[rangeDays.length - 1]) {
            setRangeDays(getDaysInRange(startOfDay(subDays(new Date(), 1)), endOfMonth(addDays(dateTo.toDate(), 1))))
        }
        if (devices && pool) {
            // check is device not have reservation
            const devicesAvailable = devices.map(d => {
                const poolDev = pool.list.filter(p => p.device === d.id);
                if (poolDev.length > 0) {
                    const res = poolDev.map(p => {
                        if (edit.id === p.id) return d
                        const startStamp = parseISO(p.stamp);
                        const endStamp = addSeconds(parseISO(p.laststamp), 1);
                        if (endStamp <= dateFrom || dateTo <= startStamp) return d
                    })
                    if (!res.some(d => d === undefined)) return res[0]
                    // return poolDev.map(p => {
                    //     const startStamp = parseISO(p.stamp);
                    //     const endStamp = parseISO(p.laststamp);
                    //     if (endStamp <= dateFrom || dateTo <= startStamp) return d
                    // })[0];

                }
                else return d
            }).filter(r => r !== undefined)
            setDevicesAvail(devicesAvailable);
            if (devFocus && !(devicesAvailable.some(d => d.id === devFocus.id)) && !editService) setDevFocus(null)
            if (drivers && (pool.aux.lastpoolr || edit) && !driversChoose.driver) {
                const crew = !edit ? pool.aux.lastpoolr.crew[0] : edit.crew[0]
                const findDriv = drivers.filter(d => d.id === crew)
                if (findDriv.length > 0) {
                    setDriversChoose({ driver: { label: findDriv[0].name, value: findDriv[0].id } })
                }
            }
            if (pool.aux.authorizers.some(u => u === user.id) || clientRoles.some(i => i.type.symbol === 'data_curator')) {
                setAdminPool(true)
                if (!edit) {
                    if (!newReservation.current) setShowReservation(false)
                }
            }
            if (!pool.aux.authorizers.some(u => u === user.id) && !clientRoles.some(i => i.type.symbol === 'data_curator') && !uiConfig.carRentalSup) navigate('/profile?autopujcovna=registrace');
            // else if (drivers) {
            //     if (drivers.filter(d => d.lstusr === user.id).length === 0) {
            //         if (drivers.some(d => d.lstsup === user.id)) {
            //             if (!edit) {
            //                 setShowReservation(false)
            //             }
            //         }
            //         else navigate('/profile?autopujcovna=registrace');
            //     }
            // }
            let q
            if (q = getQuery('reservation', location)) {
                q = pool.list.find(p => p.id === parseInt(q))
                if (q && (pool.aux.authorizers.some(u => u === user.id)) && q.status === 0) setModalOpen(q)
                clearQuery(navigate)
            }

            if (q = getQuery('edit', location)) {
                q = pool.list.find(p => p.id === parseInt(q))
                if (q && (pool.aux.authorizers.some(u => u === user.id) || q.owner === user.id)) handleEditReservation(q);
                clearQuery(navigate)
            }
        }

    }, [dateFrom, dateTo, pool, devices, rangeDays, drivers, edit])

    useEffect(() => {
        if (rangeDays && !firstRender.current) {
            WsSend({
                method: 'modify',
                id: 27,
                params: {
                    stampFrom: rangeDays[0],
                    stampTo: rangeDays[rangeDays.length - 1]
                }
            })
        }
        else firstRender.current = false;
    }, [rangeDays])

    useEffect(() => {
        if (exportPdf) setExportPdf(false)
    }, [dateFrom, dateTo, rangeDays, edit])

    useEffect(() => {
        if (editService) {
            if (showReservation) setShowReservation(false);
            if (edit) setEditService(false);
            setDevFocus(devices.filter(d => d.id === editService.device)[0])
            setDateFrom(new DateObject(parseISO(editService.stamp)))
            setDateTo(new DateObject(parseISO(editService.laststamp)))
            if (mainRemarks.current) mainRemarks.current.value = editService.iremarks;
        }
        else if (mainRemarks.current) mainRemarks.current.value = '';
    }, [editService])

    // const checkSuperVisor = (p) => {
    //     return (pool.aux.supervisors[p.pool.owner] && pool.aux.supervisors[p.pool.owner].id === user.id);
    // };

    useEffect(() => {
        if (tabs === 'waitAthorized') {
            WsSend({
                method: 'modify',
                id: 27,
                params: {
                    stampFrom: subMonths(new Date, 3),
                    stampTo: addMonths(new Date, 3),
                    isauthorized: false,
                    available: true
                }
            })
        }
        else if (tabs === 'list') {
            WsSend({
                method: 'modify',
                id: 27,
                params: {
                    stampFrom: new Date(date[0]),
                    stampTo: new Date(date[1]),
                    isauthorized: null,
                    available: null
                }
            })
        }
        else if (tabs === 'log') {
            WsSend({
                method: 'getExportNode',
                oid: 29,
                type: 'logCarRental',
                stampFrom: new Date(date[0]),
                stampTo: new Date(date[1]),
                download: pool.aux.id
            });
        }
        else {
            WsSend({
                method: 'modify',
                id: 27,
                params: {
                    stampFrom: rangeDays[0],
                    stampTo: rangeDays[rangeDays.length - 1],
                    isauthorized: null,
                    available: true
                }
            })
        }
    }, [tabs])

    useEffect(() => {
        if (date && tabs === 'list') {
            WsSend({
                method: 'modify',
                id: 27,
                params: {
                    stampFrom: new Date(date[0]),
                    stampTo: new Date(date[1]),
                    isauthorized: null
                }
            })
        }
        else if (date && tabs === 'log') {
            WsSend({
                method: 'getExportNode',
                oid: 29,
                type: 'logCarRental',
                stampFrom: new Date(date[0]),
                stampTo: new Date(date[1]),
                download: pool.aux.id
            });
        }

    }, [date])

    const columns = [
        {
            id: 0,
            field: 'virtual',
            visible: false
        },
        {
            id: 1,
            field: 'id',
            label: 'Číslo',
            width: 'auto',
        },
        {
            id: 2,
            field: 'device',
            label: 'Vozidlo',
            width: 'auto'
        },
        {
            id: 3,
            field: 'from',
            label: 'Od',
            width: 'auto'
        },
        {
            id: 4,
            field: 'to',
            label: 'Do',
            width: 'auto'
        },
        {
            id: 5,
            field: 'oname',
            label: 'Žadatel',
            width: 'auto'
        },
        {
            id: 6,
            field: 'orid',
            label: 'ID žadatel',
            width: 'auto'
        },
        {
            id: 7,
            field: 'fromTo',
            label: 'Odkud - kam',
            width: 'auto'
        },
        {
            id: 8,
            field: 'create',
            label: 'Vytvořeno',
            width: 'auto'
        },
        {
            id: 9,
            field: 'status',
            label: 'Stav',
            width: 'auto',
            visible: tabs === 'list' ? true : false
        },

    ]

    const getStatus = (status) => {
        switch (status) {
            case -1: return 'Smazaná rezervace';
            case 0: return 'Čeká na schválení';
            case 1: return 'Servis';
            case 2: return 'Sváleno';
            case 3: return 'Zamítnuto';
        }
    }

    useEffect(() => {
        if (swop.length === 2) {
            WsSend({
                method: 'setPool',
                oid: 27,
                swop: swop
            })
            console.log(swop);
            setSwop([])
        }
    }, [swop])
    useEffect(() => {
        console.log(selectedTime);

    }, [selectedTime])



    if (devices && devices.length > 0 && !loadingPage && rangeDays) return (<>
        <div className="bg-light" >
            <div className="max-w-50vh ms-3 py-2 mb-2"><LogoAutopujcovna /></div>
            {adminPool ? (
                <Nav variant="tabs" activeKey={tabs} onSelect={(e) => {
                    console.log(e, tabs, log);
                    if (tabs !== 'log') setLoadingPage(true)
                    if (e === 'log' && !log) setLoadingPage(true)
                    setTabs(e)
                }}>
                    <Nav.Item>
                        <Nav.Link eventKey={'waitAthorized'}><FaRegCalendarCheck className="fs-4" /></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={'carRental'}><FaCarSide className="fs-4" /></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={'list'}><FaRegCalendarAlt className="fs-4" /></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey={'log'}><CgNotes className="fs-4" /></Nav.Link>
                    </Nav.Item>
                </Nav>) : ''}
        </div >
        {tabs === 'waitAthorized' ? (<>
            <div className="fw-bold my-3 ms-3">Neschválené rezervace</div>
            <GridTable
                style={{ minHeight: '205px', height: (heightWindow - 150) + 'px' }}
                columns={columns}
                rows={poolWaiting ? poolWaiting.list.map(p => {
                    const device = devices.filter(d => d.id === p.device)[0];
                    const stampFormat = 'dd.MM.yyyy HH:mm';
                    return ({
                        'id': p.id,
                        'device': device.name,
                        'oname': p.oname,
                        'orid': p.orid,
                        'fromTo': p.dropoffs,
                        'from': format(parseISO(p.stamp), stampFormat),
                        'to': format(parseISO(p.laststamp), stampFormat),
                        'create': format(parseISO(p.crstamp), stampFormat),
                        'pool': p
                    })
                }).sort((a, b) => b.id - a.id) : null}
                showSearch={true}
                showColumnVisibilityManager={false}
                showRowsInformation={true}
                isPaginated={false}
                texts={{
                    search: '',
                    noResults: 'Žádné rezervace ke schválení',
                    totalRows: 'Celkem',
                }}
                onRowClick={({ data }) => {
                    setModalOpen(data.pool);
                }}
                isLoading={!poolWaiting}
                icons={{ search: <AiOutlineSearch /> }}
                className="carRental"
                enableColumnsReorder={false}
                components={{ Loader }}
            />
        </>) : ''
        }
        {
            tabs === 'carRental' ? (
                <div className="mt-1">
                    {adminPool ? <div className="ms-3">
                        <div className="fw-bold">Servisy</div>
                        <div className="d-flex flex-rows align-items-center">
                            <SelectComponentDevices
                                devices={devicesAvail}
                                devFocus={!showReservation ? devFocus : null}
                                setDevFocus={setDevFocus}
                                height={600}
                                placeholder={'Vybrat vozidlo*'}
                                wsId={27}
                            />
                            <DatePickerComponent
                                date={dateFrom}
                                setDate={setDateFrom}
                                noTime={true}
                            />
                            <DatePickerComponent
                                date={dateTo}
                                setDate={setDateTo}
                                noTime={true}
                            />
                            <Form.Group className="ms-1">
                                <Form.Control ref={mainRemarks} name="maintenance" type="text" placeholder='Popis servisu' />
                            </Form.Group>
                            <div className="mx-1">
                                {!editService ? <Button onClick={handleMaintenance} disabled={devFocus ? false : true}>Přidat servis</Button> : <>
                                    <Button variant="success" className="mx-1" onClick={handleMaintenance} >Uložit změny </Button>
                                    <Button variant="warning" className="mx-1" onClick={() => {
                                        setDevFocus(null)
                                        setEditService(false)
                                        setSelectedTime({});
                                    }} >Zrušit editace</Button>
                                    <Button variant="danger" className="mx-1" onClick={() => {
                                        WsSend({
                                            method: 'setPool',
                                            oid: 27,
                                            status: -1,
                                            id: editService.id,
                                        })
                                        setEditService(false);
                                        setDevFocus(null);
                                        setSelectedTime({});
                                    }} >Smazat servis</Button>
                                </>}

                            </div>
                        </div>
                    </div> : ''}

                    {(adminPool && showReservation) || !adminPool ? (
                        <>
                            <div onClick={() => setOpenTooltip(false)} className="d-flex justify-content-center container" >
                                <div className="border-end pe-2" style={{ minWidth: '250px' }}>
                                    <div className="fw-bold mb-3">Vyberte termín*</div>
                                    <div>
                                        <div>
                                            <div className="fw-bold ms-3">Od</div>
                                            <DatePickerComponent
                                                date={dateFrom}
                                                setDate={setDateFrom}
                                                setSelectedTime={setSelectedTime}
                                                selectedTime={selectedTime}
                                                type={'dateFrom'}
                                            />
                                        </div>
                                        <div>
                                            <div className="fw-bold ms-3">Do</div>
                                            <DatePickerComponent
                                                date={dateTo}
                                                setDate={setDateTo}
                                                setSelectedTime={setSelectedTime}
                                                selectedTime={selectedTime}
                                                type={'dateTo'}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="border-end pe-2 ms-3" style={{ minWidth: '300px' }}>
                                    <div className="fw-bold mb-3 ps-1">Zvolte dostupné vozidlo</div>
                                    <div>
                                        <SelectComponentDevices
                                            devices={devicesAvail}
                                            devFocus={devFocus}
                                            setDevFocus={setDevFocus}
                                            height={600}
                                            placeholder={'Vybrat vozidlo*'}
                                            wsId={27}
                                        />
                                    </div>
                                    {devFocus ? (
                                        <div className="mt-4">
                                            <div className="ms-1 mb-2">SPZ: {devFocus.plate}</div>
                                            <div className="ms-1 mb-2" style={{ maxWidth: '300px' }}>Typ: {devFocus.type}</div>
                                            <div className="ms-1 mb-2">Stav tachometru: {formatDistance(devFocus.tacho / 1000) + " km"}</div>
                                            <div className="ms-1 mb-2">Region / středisko: {devFocus.cctr}</div>
                                            <div style={{ maxWidth: '90px' }}>{devFocus.gitem2 ? iconKind(devFocus) : ''}</div>
                                        </div>) : ''}
                                </div>
                                <div className="ms-3">
                                    <div className="fw-bold mb-3 ps-1">Doplňte informace na žádanku</div>
                                    <Form
                                        ref={formData}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            sendReservation()
                                        }}>
                                        <div className="d-flex flex-column align-items-center">
                                            <Form.Group className="d-flex align-items-center mb-3">
                                                <Form.Label style={{ width: '320px' }} className="mb-0">Spolucestující</Form.Label>
                                                <Form.Control type="search" name="crews" defaultValue={pool.aux.lastpoolr ? pool.aux.lastpoolr.crews : null} placeholder={'doplň pokud nejedeš sám'} />
                                            </Form.Group>
                                            <Form.Group className="d-flex align-items-center mb-3">
                                                <Form.Label style={{ width: '320px' }} className="mb-0">Místo vyzvednutí *</Form.Label>
                                                <Form.Control type="search" name="pickups" defaultValue={pool.aux.lastpoolr ? pool.aux.lastpoolr.pickups : null} required placeholder={'doplň místo vyzvednutí vozidla'} />
                                            </Form.Group>
                                            <Form.Group className="d-flex align-items-center mb-3">
                                                <Form.Label style={{ width: '320px' }} className="mb-0">Náklad (druh, hmotnost)</Form.Label>
                                                <Form.Control type="search" name="loads" defaultValue={pool.aux.lastpoolr ? pool.aux.lastpoolr.loads : null} placeholder={'doplň pokud převážíš jakékoliv věci'} />
                                            </Form.Group>
                                            <Form.Group className="d-flex align-items-center mb-3">
                                                <Form.Label style={{ width: '320px' }} className="mb-0">Odkud - kam *</Form.Label>
                                                <Form.Control type="search" name="dropoffs" defaultValue={pool.aux.lastpoolr ? pool.aux.lastpoolr.dropoffs : null} required placeholder={'doplň počáteční a cílový bod cesty'} />
                                            </Form.Group>
                                            <Form.Group className="d-flex align-items-center mb-3">
                                                <Form.Label style={{ width: '320px' }} className="mb-0">Řidič kontaktuje</Form.Label>
                                                <Form.Control type="search" name="contacts" defaultValue={pool.aux.lastpoolr ? pool.aux.lastpoolr.contacts : null} placeholder={'doplň doplň pokud řidič má někomu volat'} />
                                            </Form.Group>
                                            <Form.Group className="d-flex align-items-center mb-3">
                                                <Form.Label style={{ width: '320px' }} className="mb-0">Účel jízdy *</Form.Label>
                                                <Form.Control type="search" name="reason" defaultValue={pool.aux.lastpoolr ? pool.aux.lastpoolr.reason : null} required placeholder={'doplň důvod služební cesty'} />
                                            </Form.Group>
                                            <Form.Group className="d-flex align-items-center mb-3">
                                                <Form.Label style={{ width: '320px' }} className="mb-0">Středisko *</Form.Label>
                                                <Form.Control type="search" name="cctr" required defaultValue={pool.aux.lastpoolr ? pool.aux.lastpoolr.cctr : null} placeholder={'doplň středisko'} />
                                            </Form.Group>
                                            <Form.Group className="d-flex align-items-center mb-3 w-100">
                                                <Form.Label style={{ width: '320px' }} className="mb-0">Jméno řidiče *</Form.Label>
                                                <SelectComponentDrivers
                                                    drivers={drivers}
                                                    data={driversChoose}
                                                    setData={setDriversChoose}
                                                    className={'w-100'}
                                                    height={600}
                                                    create={true}
                                                    wsSend={true}
                                                    required={true}
                                                    notClearable={true}
                                                />
                                                {/* <Form.Control type="text" name="ridic" placeholder={'doplň jméno řidiče'} /> */}
                                            </Form.Group>
                                            <Form.Group className="d-flex align-items-center w-100">
                                                <Form.Label style={{ width: '335px' }} className="mb-0">Poznámka žadatele *</Form.Label>
                                                <Form.Control as="textarea" required name="remarks" defaultValue={pool.aux.lastpoolr ? pool.aux.lastpoolr.remarks : null} type="text" placeholder={'doplň např. adresu parkování vozidla přes noc, nebo jiné poznámky'} />
                                            </Form.Group>
                                            {adminPool ?
                                                <Form.Group className="d-flex align-items-center w-100 mt-3">
                                                    <Form.Label style={{ width: '335px' }} className="mb-0">Interní poznámka</Form.Label>
                                                    <Form.Control as="textarea" name="iremarks" type="text" />
                                                </Form.Group> : ""}
                                            {adminPool && edit ?
                                                <Form.Group className="d-flex align-items-center w-100 mt-3">
                                                    <Form.Label style={{ width: '335px' }} className="mb-0">Žadatel: </Form.Label>
                                                    <Form.Control disabled={true} name="owner" type="text" />
                                                </Form.Group> : ""}
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            {edit ? (<div className="container text-center mt-2">
                                <Button variant="success" onClick={handleExternalSubmit} disabled={devFocus && driversChoose.driver ? false : true}>Uložit změny</Button>
                                <Button variant="warning" className="ms-4" onClick={() => {
                                    setEdit(false);
                                    setDevFocus(null);
                                    setSelectedTime({})
                                    if (adminPool) {
                                        setShowReservation(false)
                                    }
                                }} >Zrušit editaci</Button>
                                <Button variant="danger" className="ms-4" onClick={() => {
                                    setEdit(false);
                                    setDevFocus(null);
                                    handleConfirmReservation(edit, -1)
                                    setSelectedTime({})
                                    if (adminPool) {
                                        setShowReservation(false)
                                    }
                                }} >Smazat rezervaci</Button>
                            </div>) : <div className="container text-center mt-2">
                                <Button onClick={handleExternalSubmit} disabled={devFocus && selectedTime.dateFrom && selectedTime.dateTo ? false : true}>Rezervovat</Button>
                                {exportPdf ? (<div className="fw-bold mt-2"><div>Vyčkejte prosím na potvrzující e-mail, žádanka vám byla odeslána.<br /> O schválení objednaného vozidla ze strany Autopůjčovny ČRo budete informování e-mailem.</div>Žádanku o přepravě si můžete stáhnout <Button className="fw-bold p-0 pb-1 text-info" variant="link" onClick={() => handleDownloadPDF(exportPdf)}>{exportPdf === 'loading' ? <Spinner /> : 'zde'}</Button><div></div>
                                    <div>Děkujeme za použití on-line rezervačního systému.</div></div>) : <></>}
                            </div>}
                        </>) : ''}
                    <div className="ms-3 mt-4 mb-5">
                        <div className="fw-bold">Schéma dostupností vozidel</div>
                        <div className="d-flex">
                            {adminPool ? <Button variant="light" onClick={() => {
                                const newRange = getDaysInRange(subMonths(rangeDays[0], 1), rangeDays[rangeDays.length - 1]);
                                const scrollTo = format(newRange[0], 'ddMM');
                                setTimeout(() => {
                                    handleScrollToElement('d' + scrollTo, 'start');
                                }, 100);
                                setRangeDays(newRange)
                            }}>-30 dní</Button> : ''}
                            <Button variant="light ms-2 me-2" onClick={() => {
                                const scrollTo = format(new Date(), 'ddMM');
                                handleScrollToElement('d' + scrollTo, 'center');
                            }}>Dnes</Button>
                            <Button variant="light" onClick={() => {
                                const newRange = getDaysInRange(rangeDays[0], addMonths(rangeDays[rangeDays.length - 1], 1));
                                const scrollTo = format(newRange[newRange.length - 1], 'ddMM');
                                setRangeDays(newRange)
                                setTimeout(() => {
                                    handleScrollToElement('d' + scrollTo);
                                }, 100);
                            }}>+30 dní</Button>
                            <div className="m-auto"></div>
                            {adminPool && !edit ? (<>
                                <Button variant="light me-3" onClick={() => {
                                    newReservation.current = true;
                                    setShowReservation(true)
                                }}>Vytvoření rezervace</Button>
                            </>) : ''}
                        </div>
                        <div className="table-container mt-2">
                            <Table bordered ref={tableContainerRef} className="CarRental">
                                <thead>
                                    <tr>
                                        <th className="fixed-column bg-light"></th>
                                        {rangeDays.map((day, index) => (
                                            <th key={index} style={index < 1 ? { display: 'none' } : {}} className={`text-center p-1 bg-light border ${'d' + format(day, 'ddMM')}`}>{format(day, 'dd.MM eeeeee')}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {devices.map((dev, devIndex) => (
                                        <tr key={devIndex} >
                                            <td className={`text-nowrap fixed-column bg-light`}>{dev.name}</td>
                                            {rangeDays.map((day, dayIndex) => (
                                                <OverlayTrigger
                                                    key={dayIndex}
                                                    placement="auto"
                                                    trigger="click"
                                                    delay={{ show: 400, hide: 250 }}
                                                    overlay={(props) => (
                                                        <Tooltip {...props} >
                                                            {checkAvalibility(dev, day, true).map((pd, index) => {
                                                                if (pd.start) {
                                                                    const crewD = drivers.filter(d => d.id === pd.pool.crew[0]);
                                                                    return (
                                                                        <div key={index} className={`m-2 text-start ${dayIndex}`}>
                                                                            <div><span>
                                                                                {pd.pool.isauthorized ? 'Potvrzená blokace' : 'Čeká na potvrzení'}
                                                                            </span>
                                                                                {adminPool ? (<>
                                                                                    <Button variant="light" title="Prohodit" onClick={() => {
                                                                                        if (swop.length === 0) setSwop([pd.pool])
                                                                                        else setSwop([swop[0], pd.pool])
                                                                                        setOpenTooltip(false);
                                                                                        toast.success('Přidáno k prohození')
                                                                                    }} className="ms-3 p-1">
                                                                                        <FcRefresh className="fs-5" />
                                                                                    </Button>
                                                                                </>) : ''}
                                                                            </div>
                                                                            <div className="fw-bold">({pd.start} - {pd.end})</div>
                                                                            <div className="fw-bold mb-1">{adminPool ? ('ř.: ' + (crewD.length > 0 ? crewD[0].name : pd.pool.crew[0])) : pd.pool.oname}</div>
                                                                            {adminPool ? <>
                                                                                <div className="fw-bold">SPZ: {pd.pool.plate}</div>
                                                                                <div className="fw-bold">Žadatel: {pd.pool.oname}</div>
                                                                                <div>{pd.pool.dropoffs}</div>
                                                                                <div>{pd.pool.iremarks}</div>
                                                                            </> : ''}
                                                                            <div className="d-flex flex-row">
                                                                                {adminPool && pd.pool.status === 0 ? (<>
                                                                                    {/* <Button variant="success" onClick={() => handleConfirmReservation(pd.pool, 2)} className="mt-1 fs-6 px-2 py-1 ms-1"><FaCheckCircle className="fs-5 mb-1" />
                                                                            </Button> */}
                                                                                    <Button variant="light" title="Sválit/zamítnout rezervaci" onClick={() => {
                                                                                        setOpenTooltip(false);
                                                                                        setModalOpen(pd.pool);
                                                                                    }} className="mt-1 fs-6 px-2 py-1 ms-1">
                                                                                        <FaCheckCircle className="fs-5 mb-1 text-success" />
                                                                                        <TiDelete className="fs-5 mb-1 text-danger" />
                                                                                    </Button>
                                                                                </>) : ''}
                                                                                {(pd.pool.owner === user.id || adminPool) ? (<><Button title="Editovat rezervaci" variant="light" onClick={() => {
                                                                                    if (edit) {
                                                                                        setDevFocus(null);
                                                                                        setTimeout(() => {
                                                                                            handleEditReservation(pd.pool)

                                                                                        }, 100);
                                                                                    } else handleEditReservation(pd.pool)
                                                                                }
                                                                                } className="mt-1 fs-6 px-2 py-1 ms-1"><BsPencilSquare className="fs-6 mb-1" /></Button>
                                                                                </>) : ""}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                else {
                                                                    if (pd.maintenance && adminPool) setEditService(pd.pool)
                                                                    setOpenTooltip(false)
                                                                }

                                                            })}
                                                        </Tooltip>
                                                    )}
                                                    show={openTooltip === `${devIndex}-${dayIndex}`}
                                                    onToggle={() => setOpenTooltip(openTooltip === `${devIndex}-${dayIndex}` ? null : `${devIndex}-${dayIndex}`)}>
                                                    <th style={dayIndex < 1 ? { display: 'none' } : {}} key={dayIndex} className={`${checkAvalibility(dev, day)}`}></th>
                                                </OverlayTrigger>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div >
                    <div className="container mb-3"><span className="free carRentalLegend me-2"></span>Volné<span className="book carRentalLegend me-2 ms-3"></span>blokace (hod.)<span className="bookFull carRentalLegend me-2 ms-3"></span>blokace (celý den)<span className="service carRentalLegend me-2 ms-3"></span>servis</div>

                </div >) : ''
        }
        {
            tabs === 'list' ? (<>
                <div className="d-flex flex-rows align-items-center">
                    <div className="fw-bold my-3 ms-3 me-2">Seznam rezervací</div>
                    <DatePickerComponentFull
                        date={date}
                        setDate={setDate}
                        m={true}
                    />
                </div>
                <GridTable
                    style={{ minHeight: '205px', height: (heightWindow - 150) + 'px' }}
                    columns={columns}
                    rows={pool.list.map(p => {
                        const device = devices.filter(d => d.id === p.device)[0];
                        const stampFormat = 'dd.MM.yyyy HH:mm';
                        return ({
                            'id': p.id,
                            'device': device.name,
                            'oname': p.oname,
                            'orid': p.orid,
                            'fromTo': p.dropoffs,
                            'from': format(parseISO(p.stamp), stampFormat),
                            'to': format(parseISO(p.laststamp), stampFormat),
                            'create': format(parseISO(p.crstamp), stampFormat),
                            'status': getStatus(p.status)
                        })
                    }).sort((a, b) => b.id - a.id)}
                    showSearch={true}
                    showColumnVisibilityManager={false}
                    showRowsInformation={true}
                    isPaginated={false}
                    texts={{
                        search: '',
                        noResults: 'Žádné rezervace',
                        totalRows: 'Celkem',
                    }}
                    onRowClick={({ data }) => {
                        exportPdfID.current = data.id;
                        handleDownloadPDF(data.id);
                        setLoadingPage(true);
                    }}
                    icons={{ search: <AiOutlineSearch /> }}
                    className="carRental"
                    enableColumnsReorder={false}
                />
            </>) : ''
        }
        {
            tabs === 'log' ? (<>
                <div className="d-flex flex-rows align-items-center">
                    <div className="fw-bold my-3 ms-3 me-2">Log rezervací</div>
                    <DatePickerComponentFull
                        date={date}
                        setDate={setDate}
                        m={true}
                    />
                </div>
                {log && log.list.length > 0 ? (<GridTable
                    style={{ minHeight: '205px', height: (heightWindow - 160) + 'px' }}
                    columns={
                        [
                            {
                                id: 0,
                                field: 'empty',
                                visible: false
                            },
                            {
                                id: 1,
                                field: 0,
                                label: log.header[0],
                                width: '150px'
                            },
                            {
                                id: 3,
                                field: 2,
                                label: log.header[2],
                                width: '80px'
                            }, {
                                id: 2,
                                field: 1,
                                label: log.header[1],
                                width: '200px'
                            }
                            , {
                                id: 4,
                                field: 3,
                                label: log.header[3],
                                width: 'auto'
                            }
                        ]
                    }
                    rows={log.list.map(l => {
                        const stampFormat = 'dd.MM.yyyy HH:mm';
                        return {
                            '0': format(parseISO(l[0]), stampFormat),
                            '1': l[1],
                            '2': l[2],
                            '3': l[3]
                        }
                    })}
                    showSearch={true}
                    showColumnVisibilityManager={false}
                    showRowsInformation={false}
                    isPaginated={false}
                    texts={{
                        search: '',
                        noResults: 'Žádné rezervace',
                        totalRows: 'Celkem',
                    }}
                    icons={{ search: <AiOutlineSearch /> }
                    }
                    className="DeviceReportRaw"
                    enableColumnsReorder={false}
                />) : (log === 0 || (log.list && log.list.length === 0) ? <div className="ms-2 mt-2 fw-bold">Žádné data v logu ve zvoleném období !</div> : <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} height={heightWindow - 160} />)}
            </>) : ''
        }
        <ToastContainer
            position="bottom-center"
            autoClose={1500}
            pauseOnHover={false}
            theme="light"
            transition={Slide}
            closeOnClick
            hideProgressBar
        />
        {
            modalOpen ? <Modal size='lg' fullscreen='sm-down' centered show={modalOpen} onHide={() => { setModalOpen(false); clearQuery(navigate) }} >
                <Modal.Header closeButton >
                    <Modal.Title>Potvrzení/zamítnutí rezervace</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="d-flex align-items-center justify-content-center">
                            <Form.Label className="me-3 mb-0 w-25">Žadatel</Form.Label>
                            <Form.Control className="w-75" disabled value={modalOpen.oname} />
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center justify-content-center mt-2">
                            <Form.Label className="me-3 mb-0 w-25">Od - do, místo vyzvednutí</Form.Label>
                            <Form.Control className="w-75" disabled
                                value={`${format(parseISO(modalOpen.stamp), 'dd.MM.yyyy HH:mm')} - ${format(parseISO(modalOpen.laststamp), 'dd.MM.yyyy HH:mm')} | ${modalOpen.dropoff ? modalOpen.dropoff : ''}`} />
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center justify-content-center mt-2">
                            <Form.Label className="me-3 mb-0 w-25">Vozidlo</Form.Label>
                            <Form.Control className="w-75" disabled
                                value={modalOpen.devicename} />
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center justify-content-center mt-2">
                            <Form.Label className="me-3 mb-0 w-25" >Důvod zamítnutí</Form.Label>
                            <Form.Control className="w-75" onChange={(e) => {
                                setTimeout(() => {
                                    setModalOpen({ ...modalOpen, iremarks: e.target.value })
                                }, 500)
                            }} />
                        </Form.Group>
                    </Form>
                    <div className="text-center mt-3">
                        <Button variant="success" onClick={() => handleConfirmReservation(modalOpen, 2)} className="mt-1 fs-6 px-2 py-1 ms-3"><FaCheckCircle className="fs-5 mb-1 me-3" />
                            Schválit
                        </Button>
                        <Button variant="danger" onClick={() => handleConfirmReservation(modalOpen, 3)} className="mt-1 fs-6 px-2 py-1 ms-3"><TiDelete className="fs-5 mb-1 me-3" />
                            Zamítnout
                        </Button>
                    </div>

                </Modal.Body>
            </Modal> : <></>
        }
    </>)
    else return <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} />
}