import React, { useEffect, useState, useRef } from "react";
import { XMLParser } from 'fast-xml-parser';
import { parseISO, format, addMinutes, startOfDay } from 'date-fns'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";

import { DatePickerComponentFull } from '../../components/DatePickerComponent/DatePickerComponent'
import { newDate, lastThreeMonths } from "../DatePickerComponent/DateSelect";
import { formatDateObjectTime } from "../../components/DatePickerComponent/DatePickerConfig"
import { SmallCircel } from "../../images";

export default function DDDViewFile({ openFile, fileInfoOpen, setOpenFile }) {
    const { t } = useTranslation('translation', { keyPrefix: 'ddd' });
    const [stamp, setStamp] = useState(false);
    const [date, setDate] = useState(false);
    const refRender = useRef(null);

    useEffect(() => {
        if (!date && sessionStorage.getItem('date-ddd')) setDate(newDate(JSON.parse(sessionStorage.getItem('date-ddd'))))
        else if (!date) {
            const firstDate = lastThreeMonths();
            if (firstDate.length > 1) {
                setDate(firstDate)
            }
        }
        if (date.length > 1) {
            if (refRender.current) sessionStorage.setItem('date-ddd', JSON.stringify([date[0].format(formatDateObjectTime), date[1].format(formatDateObjectTime)]))
            refRender.current = true;
        }

        if (date && date.length === 2) {
            let stampFrom = new Date(date[0].format(formatDateObjectTime));
            let stampTo = new Date(date[1].format(formatDateObjectTime));
            setStamp([stampFrom, stampTo])
        }

    }, [date])


    function minuteFromMidnight(minutes) {
        return format(addMinutes(startOfDay(new Date()), minutes), 'HH:mm')
    }

    function isStampInRange(stamp, from, to) {
        return stamp >= from && stamp <= to;
    }

    function checkActivity(a, p, s, c) {

        if (a === 0) return 'rest';
        if (a === 1) return 'available';
        if (a === 2) return 'work';
        if (a === 3) return 'driving';

        // if (p === 1 && c === 1 && a === 0) return 'rest'
        // if (p === 1 && a === 0) return 'unkown';
        // if (p !== 1 && a === 2) return 'work';
        // if (p !== 1 && a === 3) return 'driving';
        // if (p !== 1 && a === 0) return 'rest';
    }

    function findFirstDrivingOrWorkFollowedByRest(data) {
        let firstIndex = -1;
        let lastIndex = -1;

        // opravit výkon
        for (let i = 0; i < data.length; i++) {
            if ((data[i].activities === 'driving' || data[i].activities === 'work' || data[i].activities === 'available') && !lastIndex) {
                lastIndex = i;
            }
        }

        for (let i = data.length - 1; i >= 0; i--) {
            if ((data[i].activities === 'driving' || data[i].activities === 'work' || data[i].activities === 'available') && !firstIndex) {
                firstIndex = i;
            }
        }


        return { firstIndex, lastIndex };
    }

    function dayActivity(dayAct, index) {
        let res = [];
        if (dayAct[index].activities && dayAct[index].activities.length > 1) {
            dayAct[index].activities.forEach((act, i) => {
                if (i === 0) {
                    const start = '00:00';
                    const end = minuteFromMidnight(act.t);
                    const time = act.t;
                    const a = dayAct[index].a;
                    const p = dayAct[index].p;
                    const s = dayAct[index].s;
                    const c = dayAct[index].c;
                    const dur = act.t;
                    const activities = checkActivity(a, p, s, c)
                    res.push({ start: start, end: end, time: time, activities: activities, duration: dur, manualy: (c === 1 && p === 1) ? true : false, unknown: (c === 0 && p === 1) ? true : false })
                }
                const a = act.a;
                const p = act.p;
                const s = act.s;
                const c = act.c;
                const activities = checkActivity(a, p, s, c)
                if (i === (dayAct[index].activities.length - 1)) {
                    const start = minuteFromMidnight(act.t);
                    const time = act.t;
                    const end = '24:00';
                    const dur = 1440 - act.t;
                    res.push({ start: start, end: end, time: time, activities: activities, duration: dur, manualy: (c === 1 && p === 1) ? true : false, unknown: (c === 0 && p === 1) ? true : false })
                }
                else {
                    const start = minuteFromMidnight(act.t);
                    const time = act.t;
                    const end = minuteFromMidnight(dayAct[index].activities[i + 1].t);
                    const dur = dayAct[index].activities[i + 1].t - act.t;
                    res.push({ start: start, end: end, time: time, activities: activities, duration: dur, manualy: (c === 1 && p === 1) ? true : false, unknown: (c === 0 && p === 1) ? true : false })
                }
            })
        }
        // else if (dayAct[index].activities ===) {
        //     const start = '00:00';
        //     console.log(dayAct[index]);
        //     const end = minuteFromMidnight(dayAct[index].activities.t);
        //     const a = parseInt(dayAct[index].a);
        //     const p = parseInt(dayAct[index].p);
        //     const s = parseInt(dayAct[index].s);
        //     const c = parseInt(dayAct[index].c);
        //     const dur = parseInt(dayAct[index].activities.t);
        //     const activities = checkActivity(a, p, s, c)
        //     res.push({ start: start, end: end, activities: activities, duration: dur })

        //     const start2 = minuteFromMidnight(dayAct[index].activities.t);
        //     const end2 = '24:00'
        //     const a2 = parseInt(dayAct[index].activities.a);
        //     const p2 = parseInt(dayAct[index].activities.p);
        //     const s2 = parseInt(dayAct[index].activities.s);
        //     const c2 = parseInt(dayAct[index].activities.c);
        //     const dur2 = 1440 - parseInt(dayAct[index].activities.t);;
        //     const activities2 = checkActivity(a2, p2, s2, c2)
        //     res.push({ start: start2, end: end2, activities: activities2, duration: dur2 })

        // }
        else {
            const start = '00:00';
            const end = '24:00';
            const time = 1440;
            const a = dayAct[index].a;
            const p = dayAct[index].p;
            const s = dayAct[index].s;
            const c = dayAct[index].c;
            const dur = 1440;
            const activities = checkActivity(a, p, s, c)
            res.push({ start: start, end: end, time: time, activities: activities, duration: dur, manualy: (c === 1 && p === 1) ? true : false, unknown: (c === 0 && p === 1) ? true : false })
        }
        return res
    }

    if (openFile.dddx) {
        //parse xml to json
        const options = {
            attributeNamePrefix: '_',
            attrNodeName: 'attributes',
            ignoreAttributes: false,
            ignoreNameSpace: false,
            textNodeName: 'value',
            parseAttributeValue: false,
            parseTrueNumberOnly: false,
            arrayMode: true,
            alwaysCreateTextNode: true,
            parseTagValue: true,
        };
        const parser = new XMLParser(options);
        let jd = parser.parse(openFile.dddx);
        console.log(jd);
    }

    if (openFile.ddd) {
        // console.log(ddd);
        if (openFile.ddd.FileType !== 'DriverFile') return (
            <Modal
                centered
                show={openFile.ddd}
                size="xl"
                fullscreen='xl-down'
                onHide={() => { setOpenFile(false) }} >
                <Modal.Header closeButton >
                    <div>
                        <Modal.Title>{`${t('tachograph')} - ${openFile.ddd.VehicleIdentification.VehicleRegistrationNumber}`}</Modal.Title>
                    </div>
                    <div className="m-auto"></div>
                    <DatePickerComponentFull
                        date={date}
                        setDate={setDate}
                        option={{ page: 'dddView' }}
                    />
                </Modal.Header>
                <Modal.Body>
                    DDD tachografu zatím není podporován
                </Modal.Body>
            </Modal>
        )

        let driverActivityData = openFile.ddd.DriverActivityData;

        // if(ddd.DriverActivityData.length>1){
        //     console.log(ddd.DriverActivityData);
        //     driverActivityData = ddd.DriverActivityData[0].activityDailyRecords.DriverActivity.concat(ddd.DriverActivityData[1].activityDailyRecords.DriverActivity)
        // }
        // else{
        //     driverActivityData = ddd.DriverActivityData.activityDailyRecords.DriverActivity;
        // }

        // console.log(dayActivity(driverActivityData, 111));

        let dataDDDparse = []
        for (let i = 0; i < driverActivityData.length; i++) {
            const date = new Date(parseISO(driverActivityData[i].date));
            if (isStampInRange(date, stamp[0], stamp[1])) {
                const day = (format(date, 'dd.MM.yyyy') + ' - ' + format(date, 'eeeeee').charAt(0).toUpperCase() + format(date, 'eeeeee').slice(1))
                const act = dayActivity(driverActivityData, i)
                // console.log(act);
                let total = {};
                if (act.length > 1) {
                    const { firstIndex, lastIndex } = findFirstDrivingOrWorkFollowedByRest(act);
                    // console.log(firstIndex, lastIndex);
                    //const workDailyTime = minuteFromMidnight(act[lastIndex].time - act[firstIndex].time);
                    // console.log(act[lastIndex], act[firstIndex]);
                    // výkon dodělat opravit 
                    const workDailyTime = 0;
                    const driverActivitySum = {};
                    act.forEach(item => {
                        const { activities, duration } = item;
                        if (!driverActivitySum[activities]) {
                            driverActivitySum[activities] = 0;
                        }
                        driverActivitySum[activities] += duration;
                    });
                    for (const activities in driverActivitySum) {
                        const formattedDuration = minuteFromMidnight(driverActivitySum[activities]);
                        total[activities] = formattedDuration;
                    }
                    total = { ...total, workDailyTime: workDailyTime }
                    // console.log(total);
                }
                else {
                    total[act[0].activities] = '24:00';
                }

                dataDDDparse.push({
                    activity: act,
                    date: day,
                    total: total,
                    raw: driverActivityData[i],
                    indexRaw: i
                })
            }
        }

        function DriverActivity({ data }) {
            return (
                <div>
                    {data.map(d => {
                        const activity = d.activity.map(a => {
                            return (
                                <div className="fw-normal" key={Math.random()}>{a.start} - {a.end} - {a.activities} - {minuteFromMidnight(a.duration)}</div>
                            )
                        })
                        return <div className="fw-bold" key={Math.random()}>
                            {d.date}
                            {d.total.driving ? " řízení: " + d.total.driving : ''}
                            {d.total.work ? " práce: " + d.total.work : ''}
                            {d.total.rest ? " odpočinek: " + d.total.rest : ''}
                            {d.total.workDailyTime ? " výkon: " + d.total.workDailyTime : ''}
                            {activity}
                        </div>
                    }).reverse()
                    }
                </div>
            );
        }

        const detailActivity = (props, data) => (
            <Popover id="popover-basic" {...props}>
                <Popover.Header as="h6">{`${t(data.activities)} ${minuteFromMidnight(data.duration)}h`}</Popover.Header>
                <Popover.Body>
                    <div>{`${data.manualy ? t('manualyActivity') : ''} ${data.unknown ? t('unkownActivity') : ''}`}</div>
                    <div>od {data.start} do {data.end}</div>
                </Popover.Body>
            </Popover>
        );


        function DriverGraph({ data }) {
            if (data.length === 0) return (<div>{t('noData')}</div>)
            return (
                <div className="DDDchart">
                    {data.map((day, index) => (
                        <div key={'dayActivity' + index}>
                            <div className="d-flex pt-2 pb-2">
                                {day.date}
                                {<span className="d-flex ms-3">
                                    <span ><SmallCircel className="DDDrestColor" /> {`${t('rest')}-${day.total.rest}`} </span>
                                    {day.total.driving ? <span><SmallCircel className="ms-2 DDDdrivingColor" /> {`${t('driving')}-${day.total.driving}`} </span> : null}
                                    {day.total.work ? <span ><SmallCircel className="ms-2 DDDworkColor" /> {`${t('work')}-${day.total.work}`} </span> : null}
                                    {day.total.workDailyTime ? <span className="ms-2"> {`${t('workDailyTime')}-${day.total.workDailyTime}`}</span> : null}
                                </span>}
                            </div>
                            <div className="DDDchartDay">
                                {day.activity.map((activity, activityIndex) => (
                                    activity.duration !== 0 ? (
                                        <OverlayTrigger
                                            key={`activities-${activityIndex}`}
                                            placement="bottom"
                                            delay={{ show: 150, hide: 150 }}
                                            overlay={(props) => detailActivity(props, activity)}
                                        >
                                            <div
                                                className={`DDDactivity ${activity.activities} ${activity.manualy ? 'manualy' : ''}${activity.unknown ? 'unknown' : ''}`}
                                                // ${activity.manualy ? 'manualy' : ''}
                                                style={{
                                                    width: `${(parseInt(activity.duration))}px`,
                                                }}
                                            >
                                            </div>
                                        </OverlayTrigger>) : null
                                ))}
                            </div>
                        </div>
                    )).reverse()}
                </div>
            );
        };
        // console.log(dataDDDparse);
        // return (
        //     <div>
        //         {<DriverGraph data={dataDDDparse} />}
        //     </div>
        // )

        const downloadFile = (f) => {
            const uint8Array = new Uint8Array(f.contents);
            const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = f.name;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }

        return (
                <Modal
                    centered
                    show={openFile.ddd}
                    size="xl"
                    fullscreen='xl-down'
                    onHide={() => { setOpenFile(false) }} >
                    <Modal.Header closeButton >
                        <div>
                            <Modal.Title>{`${t('driver_card')} - ${openFile.ddd.EFIdentification.holderFirstNames} ${openFile.ddd.EFIdentification.holderSurname} ${format(parseISO(openFile.ddd.EFIdentification.cardHolderBirthDate), 'dd.MM.yyyy')}`}</Modal.Title>
                            {fileInfoOpen.lastDownload ? <div>{`${t('lastDownload')} ${format(parseISO(fileInfoOpen.lastDownload), 'dd.MM.yyyy HH:mm')}`}</div> : <></>}
                            <div>{`${t('cardExpiryDate')} ${format(parseISO(openFile.ddd.EFIdentification.cardExpiryDate), 'dd.MM.yyyy')}`}</div>
                            <Button variant="secondary" onClick={() => downloadFile(openFile)}>Stahnut soubor</Button>
                        </div>
                        <div className="m-auto"></div>
                        <DatePickerComponentFull
                            date={date}
                            setDate={setDate}
                            option={{ page: 'dddView' }}
                            m={true}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <DriverGraph data={dataDDDparse} />
                    </Modal.Body>
                </Modal>
        )
    }
}