import React, { useState, useContext, useEffect, useRef, Suspense } from "react";
import { client } from 'websocket';
import { useTranslation, Trans } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Button, Container, Dropdown, DropdownButton, Form, InputGroup, Tooltip, OverlayTrigger, ButtonGroup, FloatingLabel } from "react-bootstrap"
import { format } from 'date-fns';
import * as Sentry from "@sentry/react";

import { FilterTime } from "../../images";
import { GoGraph, GoListUnordered } from "react-icons/go";
import { SiMicrosoftexcel } from "react-icons/si";
import { LuDownload } from "react-icons/lu";
import { FiFilter } from "react-icons/fi";
import Select from 'react-select'

import { WsSend, WsAnal, WSdata, WSdataList, downloadFromContent, clearWSQueue } from "../../scripts/ws";
import { useWindowSize, UserContext, isClient } from "../../components";
import { FilterComponentDevices, SelectComponentDevices, FilterComponentDrivers, SelectComponentStats } from "../../components/SelectComponent/SelectComponent"
import { DatePickerComponentFull } from "../../components/DatePickerComponent/DatePickerComponent"
import { getRowsDriverCheck, getRowsSensorsReport, getRowsStatsSelection, getRowsStatsVehicles } from "../../components/Stats/statsRows"
import { getCallRender, getLabel } from "../../components/Stats/statsColumns";
import { CostumerReport, getColumnsCostumerReport } from "../../components/Stats/CostumerReport";
// import { StatsVehicles, DriverCheck, SensorsReport } from "../../components/Stats/";
import usePrevious from "../../components/usePrevious"
import { columnsSensorsReport as columnsSensorsReportDefault, columnsDriverCheck as columnsDriverCheckDefault, columnsDriverCheck2 as columnsDriverCheck2Default, columnsStatsVehicles as columnsStatsVehiclesDefault, columnsStatsSelection as columnsStatsSelectionDefault, columnsCRoCsv, columnsCRoPdf } from "../../components/Stats/statsColumns";

const Excel = React.lazy(() => import('../../components/export').then(module => ({ default: module.Excel })));
const Pdf = React.lazy(() => import('../../components/export').then(module => ({ default: module.Pdf })));
const ExportEmail = React.lazy(() => import('../../components/exportEmail'));
const StatsVehicles = React.lazy(() => import('../../components/Stats/').then(module => ({ default: module.StatsVehicles })));
const StatsSelection = React.lazy(() => import('../../components/Stats/').then(module => ({ default: module.StatsSelection })));
const DriverCheck = React.lazy(() => import('../../components/Stats/').then(module => ({ default: module.DriverCheck })));
const SensorsReport = React.lazy(() => import('../../components/Stats/').then(module => ({ default: module.SensorsReport })));
// const CostumerReport = React.lazy(() => import('../../components/Stats/').then(module => ({ default: module.CostumerReport })));

export default function Reports() {
    const { t, i18n } = useTranslation('translation');
    let listStats = []

    document.title = i18n.t('widget.reports')

    // const dev = useContext(UserContext).dev
    const driv = useContext(UserContext).driv
    const listClientSearch = useContext(UserContext).listClientSearch
    const columnsDriverCheck = useContext(UserContext).uiConfig.columnsDriverCheck;
    const columnsDriverCheck2 = useContext(UserContext).uiConfig.columnsDriverCheck2;
    const columnsSensorsReport = useContext(UserContext).uiConfig.columnsSensorsReport;
    const columnsStatsVehicles = useContext(UserContext).uiConfig.columnsStatsVehicles;
    const columnsStatsSelection = useContext(UserContext).uiConfig.columnsStatsSelection;
    const uiStatsFilter = useContext(UserContext).uiConfig.statsFilter;
    const clientFilters = useContext(UserContext).clientFilters;
    const { uiConfig, clientRoles, setListClientSearch, setDriv, setNotification, dev, costumer, user } = useContext(UserContext);

    const [devices, setDevices] = useState();
    const [drivers, setDrivers] = useState(driv);
    const [driverCheck, setDriverCheck] = useState(false);
    const [report, setReport] = useState(false);
    const [stats, setStats] = useState(false);
    const [statsSelection, setStatsSelection] = useState(false);
    const [statsFilter, setStatsFilter] = useState(uiStatsFilter ? uiStatsFilter : { groupDevice: true, groupDriver: false, groupPurpose: false, groupDay: true });
    const [grid, setGrid] = useState(localStorage.getItem("stats.grid") ? JSON.parse(localStorage.getItem("stats.grid")) : 5);
    const [date, setDate] = useState(false);
    const heightWindow = useWindowSize().height;
    const widthWindow = useWindowSize().width;
    const [updatePage, setUpdatePage] = useState(false);
    const [devFocus, setDevFocus] = useState(false);
    const prevDevFocus = usePrevious(devFocus);
    const [filterDevice, setFilterDevice] = useState(sessionStorage.getItem("stats.filterDevice") ? JSON.parse(sessionStorage.getItem("stats.filterDevice")) : false);
    const [filterDrivers, setFiletrDrivers] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [fencesData, setFencesData] = useState(false);
    const [contacts, setContacts] = useState(false);
    const [userData, setUserData] = useState(false);
    const [graph, setGraph] = useState(localStorage.getItem("stats.sensorGraph") ? JSON.parse(localStorage.getItem("stats.sensorGraph")).show : false);
    const [filterSensors, setFilterSensors] = useState(false);
    const [exportGraph, setExportGraph] = useState(false);
    const [costumerReportData, setCostumerReportData] = useState(false);
    const [CRoAddRows, setCRoAddRows] = useState({ modal: false, row: false });
    const refWS4 = useRef(null);
    const refFirst = useRef(null);
    const costumerReport = useRef(null);

    if (clientRoles.some(i => i.type.symbol === 'view_history')) {
        listStats.push({ value: 'statsVehicles', label: t('widget.statsVehicles') })
        listStats.push({ value: 'statsSelection', label: t('widget.statsSelection') })
        if (devices && devices.length > 0 && devices.some(d => d.cfg.includes('anal'))) {
            listStats.push({ value: 'driverCheck2', label: t('widget.DriverCheck2') })
            if (devices.some(d => d.icon.includes('truck') || d.icon.includes('lorry') || d.icon.includes('bus'))) listStats.push({ value: 'driverCheck', label: t('widget.DriverCheck') })
        };
    }
    if (clientRoles.some(i => i.type.symbol === 'view_report')) listStats.push({ value: 'sensorsReport', label: t('widget.sensorsReport') });

    // if (costumer && (costumer.match(/\[(.*?)\]/)[1] === '06065' || costumer.match(/\[(.*?)\]/)[1] === '6065') && client.url === 'wss://ws02.logiscare.com/')
    if (isClient('geoTest', costumer)) {
        costumerReport.current = { type: 'geoTest6065', device: (typeof driv === 'number' ? driv : null) };
        listStats.push({ value: 'costumerReport', label: t('stats.costumerReport') + costumer.split(']')[1].trim() })
    }

    const [statsType, setStatsType] = useState(localStorage.getItem("stats.statsType") ? JSON.parse(localStorage.getItem("stats.statsType")) : listStats[0]);

    client.onmessage = (message) => {
        let data = WsAnal(message)
        //  console.log('new data Reports', data);
        // update message
        if (data && data.length > 0) {
            setDevices(WSdata(data, devices, 4));
            if (data.some(d => d.object === 15)) {
                setDriverCheck(WSdataList(data, driverCheck, 15));
                setLoading(false)
            }
            if (data.some(d => d.object === 11)) setListClientSearch(WSdataList(data, listClientSearch, 11));
            if (!uiConfig.sesorsReportRaw) {
                setReport(WSdataList(data, report, 19));
            }
            else {
                if (data.some(i => i.object === 19)) {
                    const origin = WSdataList(data, report, 19);
                    let sensors = origin.aux.sensors;
                    const res = origin.list.map(r => {
                        if (r.edit) return r
                        else {
                            const inputString = r.pars
                            const regex = /(\w+)=(\-?\d+(\.\d+)?)/g;
                            let match;
                            const extractedValues = {};
                            while ((match = regex.exec(inputString)) !== null) {
                                extractedValues[match[1]] = match[2];
                                if (!sensors.includes(match[1])) sensors.push(match[1])
                            }
                            return { ...r, pars: extractedValues, edit: true }
                        }
                    })
                    const aux = { sensors: sensors }
                    setReport({ ...origin, aux: aux, list: res });
                }

            }
            if (data.some(d => d.object === 33)) {
                setStatsSelection(WSdataList(data, statsSelection, 33));
                setLoading(false)
            }
            if (data.some(d => d.object === 20)) {
                setStats(WSdataList(data, stats, 20));
                setLoading(false)
            }
            if (data.some(d => d.object === 24)) setContacts(WSdata(data, contacts, 24))
        }
        // first new message from query
        else {
            if (data) {
                if (data.id === 4) setDevices(WSdata(data, null, 4).list)
                if (data.id === 8) setUserData(WSdata(data, null, 8))
                if (data.id === 11) setListClientSearch(WSdata(data, null, 11));
                if (data.id === 26) setNotification(WSdata(data, null, 26));
                if (data.id === 15) {
                    setDriverCheck(WSdata(data, null, 15));
                    setLoading(false)
                }
                if (data.id === 16) {
                    const res = WSdata(data, null, 16).list;
                    setDrivers(res);
                    setDriv(res);
                };
                if (data.id === 19) {
                    if (!uiConfig.sesorsReportRaw) {
                        setReport(WSdata(data, null, 19))
                    }
                    else {
                        let sensors = [];
                        let parseTmp = data.value.list.map(s => {
                            const inputString = s.pars
                            const regex = /(\w+)=(\-?\d+(\.\d+)?)/g;
                            let match;
                            const extractedValues = {};
                            while ((match = regex.exec(inputString)) !== null) {
                                extractedValues[match[1]] = match[2];
                                if (!sensors.includes(match[1])) sensors.push(match[1])
                            }
                            return { ...s, pars: extractedValues, edit: true }
                        })
                        const aux = { sensors: sensors }
                        const value = { ...data.value, aux: aux, list: parseTmp }
                        const data_new = { ...data, value }
                        setReport(WSdata(data_new, null, 19));
                    }
                }
                if (data.id === 20) {
                    setStats(WSdata(data, null, 20));
                    setLoading(false)
                }
                if (data.id === 22) setFencesData(WSdata(data, null, 22))
                if (data.oid && data.oid === 23) setFencesData(false)
                if (data.id === 24) setContacts(WSdata(data, null, 24).list);
                if (data.oid && data.oid === 28) setCostumerReportData(data.value)
                if (data.oid && data.oid === 29) {
                    if (data.value) {
                        downloadFromContent(data.value, `export_${costumerReport.current.type}_${format(date[0].toDate(), 'yyyy_MM')}.xlsx`)
                    }
                }
                if (data.id === 33) {
                    setStatsSelection(WSdata(data, null, 33))
                    setLoading(false)
                }
            }
        }
        setTimeout(() => {
            if (loading) setLoading(false);
        }, 3000);
    }

    if (!devices && !refWS4.current) {
        refWS4.current = true;
        const params = { method: 'getDevices', oid: 4 };
        if (statsType.value === 'sensorsReport') params.restriction = 'view_report'
        else params.restriction = 'view_history'
        WsSend(params);
    }

    if (!drivers && statsType.value !== 'sensorsReport') {
        WsSend({ method: 'getDrivers', oid: 16, ddd: null });
        setDrivers([]);
    }

    useEffect(() => {
        setUpdatePage(!updatePage)
    }, [heightWindow])

    client.onclose = (e) => {
        console.log('WS connection close on reports clear data', e);
        Sentry.captureException(e)
        clearWSQueue()
        const saveStatsType = statsType;
        setStatsType(false);
        setDrivers(driv);
        setStats(false);
        setDriverCheck(false);
        setDevices(false);
        setReport(false)
        setFilterDevice(false);
        setFiletrDrivers(false);
        setDevFocus(false);
        refWS4.current = false;
    }

    useEffect(() => {
        //delete all if switch costumer
        if (!dev && (stats || driverCheck || report) && refWS4.current && refFirst.current) {
            const saveStatsType = statsType;
            setStatsType(false);
            setDrivers(driv);
            setStats(false);
            setDriverCheck(false);
            setDevices(false);
            setReport(false)
            setFilterDevice(false);
            setFiletrDrivers(false);
            setDevFocus(false);
            refWS4.current = false;
            console.log('costumer change delete data from reports');
            setTimeout(() => {
                setStatsType(saveStatsType);
            }, 100);
        }
        else refFirst.current = true;
    }, [costumer])

    const getColumns = (c, report) => {
        let columns = c;
        if (report && report.aux) columns = c.filter(data => report.aux.sensors.includes(data.field) || data.field === 'stamp' || data.field === 'address' || data.field === 'gps')
        return columns.map(data => {
            const saveC = c.find(i => i.id === data.id)
            let res = {
                ...data,
                visible: saveC.visible,
                width: saveC.width,
                label: getLabel(data.field, (statsType.value === 'sensorsReport' ? devFocus : null))
            }
            const callrender = getCallRender(data.field);
            //   const getsortf = getSortF(data.field);
            if (callrender) res = { ...res, cellRenderer: callrender }
            //   if (getsortf) res = { ...res, sort: getsortf }

            return res
        })
    }
    useEffect(() => {
        if (devices && !devFocus && statsType.value === 'sensorsReport') {
            const device = devices.find((d) => d.id == localStorage.getItem('devFocus'))
            if (device) setDevFocus(device);
            else setDevFocus(devices[0]);
        }
    }, [devices, statsType])

    useEffect(() => {
        if (devices && refWS4.current) {
            WsSend({ method: 'unregister', id: 4 });
            refWS4.current = false;
        }
    }, [devices])

    const listSensor = () => {
        const columnSensor = getColumns(columnsSensorsReport ? columnsSensorsReport : columnsSensorsReportDefault, report);
        const sensors = report.aux.sensors.map(s => {
            let labelKey = t('devices.' + s)
            if (s.includes('tmp')) {
                if (s === 'tmp0') labelKey = t('devices.tmp0');
                else {
                    if (devFocus.sensors[s]) labelKey = t('devices.temperature') + ' ' + s.slice(3) + ' - ' + devFocus.sensors[s];
                    else labelKey = t('devices.temperature') + ' ' + s.slice(3)
                }
            }
            if (s.includes('hmd')) {
                if (devFocus.sensors[s.slice(0, -3)]) labelKey = t('devices.humidity') + ' ' + s.slice(3).slice(0, -3) + ' - ' + devFocus.sensors[s.slice(0, -3)];
                else labelKey = t('devices.humidity') + ' ' + s.slice(3).slice(0, -3)
            }

            return { value: s, label: labelKey }
        }).filter(select => columnSensor.some(c => c.field === select.value && c.visible && select.value !== 'ucode' && select.value !== 'position' && select.value !== 'stamp'))

        const tmp = sensors.filter(s => s.value.startsWith('tmp'))
        if (tmp.length > 1) {
            sensors.unshift({ value: 'tmpAll', label: t('devices.tmpAll'), tmpArray: tmp })
        }
        return sensors
    }

    return (

        <div>
            <div className='gridHandle d-flex justify-conten-evenly'>
                <span className={`ms-2 gridHandleOn `}>{t('widget.reports')}</span>
                <span className="m-auto">  </span>
            </div>
            <div style={{ height: (heightWindow - 24) + 'px' }}>
                <div className="d-flex pt-3 pb-3">
                    <SelectComponentStats
                        statsType={statsType}
                        setStatsType={setStatsType}
                        listStats={listStats}
                        height={heightWindow - 24}
                        setDate={setDate}
                    />
                    {statsType.value === 'sensorsReport' ? (
                        <SelectComponentDevices
                            devices={devices}
                            devFocus={devFocus}
                            setDevFocus={setDevFocus}
                            WsSend={WsSend}
                            height={heightWindow - 24}
                            wsId={19}
                            setLoading={setLoading}
                            prevDevFocus={prevDevFocus}
                        />
                    ) : ""}

                    {statsType.value === 'driverCheck' || statsType.value === 'driverCheck2' || statsType.value === 'statsVehicles' || statsType.value === 'statsSelection' ? (
                        <>
                            <FilterComponentDevices
                                devices={devices}
                                filterDevice={filterDevice}
                                setFilterDevice={setFilterDevice}
                                height={heightWindow - 24}
                                filters={clientFilters}
                            />

                        </>
                    ) : ""}

                    {statsType.value === 'driverCheck' || statsType.value === 'driverCheck2' || statsType.value === 'statsSelection' ? (
                        <>
                            <FilterComponentDrivers
                                drivers={drivers}
                                filterDrivers={filterDrivers}
                                setFiletrDrivers={setFiletrDrivers}
                                height={heightWindow - 24}
                            />
                        </>
                    ) : ""}

                    <>
                        <DatePickerComponentFull
                            date={date}
                            setDate={setDate}
                            w={widthWindow - 400}
                            h={heightWindow - 24}
                            m={false}
                        />
                    </>


                    {statsType.value === 'sensorsReport' && report ? (
                        <>
                            <ButtonGroup className="my-2">
                                <Button active={!graph} variant="light" onClick={() => {
                                    setGraph(false);
                                    localStorage.setItem('stats.sensorGraph', JSON.stringify({ show: false }))
                                }}><GoListUnordered className="fs-5" /></Button>
                                <Button active={graph} variant="light" onClick={() => {
                                    setGraph(true)
                                    localStorage.setItem('stats.sensorGraph', JSON.stringify({ show: true }))
                                }}><GoGraph className="fs-5" /></Button>
                            </ButtonGroup>
                            {!graph ?
                                <InputGroup className="m-2 grid search">
                                    <InputGroup.Text className="cursorhelp">
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 400, hide: 250 }}
                                            overlay={(props) => (
                                                <Tooltip {...props}>
                                                    {t('stats.gridFilter')}
                                                </Tooltip>
                                            )}
                                        >
                                            <div><FilterTime /></div>
                                        </OverlayTrigger>
                                    </InputGroup.Text>

                                    <Form.Control min={0} type="number" value={grid} onChange={(e) => {
                                        setGrid(e.target.value)
                                        // if (parseInt(e.target.value) || parseInt(e.target.value) === 0) {
                                        //     localStorage.setItem('stats.grid', parseInt(e.target.value))
                                        //     setGrid(parseInt(e.target.value));
                                        // }
                                    }} />
                                </InputGroup> : <div style={{ minWidth: '150px' }}>
                                    <Select
                                        // defaultMenuIsOpen
                                        className="react-select-container my-2 ms-2"
                                        classNamePrefix="react-select"
                                        isMulti
                                        options={listSensor()}
                                        placeholder={t('stats.filterSensors')}
                                        noOptionsMessage={() => t('notFound')}
                                        defaultValue={filterSensors}
                                        onChange={(selected) => {
                                            const res = []
                                            selected.forEach(s => {
                                                if (s.value === 'tmpAll') s.tmpArray.forEach(tmp => res.push(tmp))
                                                else res.push(s)
                                            })
                                            setFilterSensors(res)
                                        }}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, minWidth: 250 }) }}
                                        menuPortalTarget={document.body}
                                    />
                                </div>}
                        </>
                    ) : ""}

                    {statsType.value === 'statsSelection' ? (
                        <>
                            <DropdownButton
                                drop="up"
                                variant="light"
                                className="mx-1 my-2"
                                title={
                                    <div className="my-2">
                                        <FiFilter className='fs-5' />
                                    </div>
                                }
                            >
                                <div className="px-3 py-1 d-flex flex-column">
                                    <div className="mb-2">{t('stats.groupBy')}</div>
                                    <Form>
                                        <Form.Check
                                            className="cursorpointer"
                                            inline
                                            label={t('stats.groupByDevice')}
                                            type={'checkbox'}
                                            checked={statsFilter.groupDevice}
                                            onChange={() => setStatsFilter({ ...statsFilter, groupDevice: !statsFilter.groupDevice })}
                                        />
                                        <Form.Check
                                            className="cursorpointer"
                                            inline
                                            label={t('stats.groupByDriver')}
                                            type={'checkbox'}
                                            checked={statsFilter.groupDriver}
                                            onChange={() => setStatsFilter({ ...statsFilter, groupDriver: !statsFilter.groupDriver })}
                                        />
                                        <Form.Check
                                            className="cursorpointer"
                                            inline
                                            label={t('stats.groupByPurpose')}
                                            type={'checkbox'}
                                            checked={statsFilter.groupPurpose}
                                            onChange={() => setStatsFilter({ ...statsFilter, groupPurpose: !statsFilter.groupPurpose })}
                                        />
                                    </Form>
                                    <Form className="mt-2">
                                        <Form.Check
                                            className="cursorpointer"
                                            inline
                                            label={t('stats.groupByStamp')}
                                            type={'radio'}
                                            checked={!statsFilter.groupDay}
                                            onChange={() => setStatsFilter({ ...statsFilter, groupDay: false })}
                                        />
                                        <Form.Check
                                            className="cursorpointer"
                                            inline
                                            label={t('stats.groupByDay')}
                                            type={'radio'}
                                            checked={statsFilter.groupDay}
                                            onChange={() => setStatsFilter({ ...statsFilter, groupDay: true })}
                                        />
                                    </Form>
                                </div>
                            </DropdownButton>
                        </>
                    ) : ""}

                    <Suspense >
                        {date && stats && statsType.value === 'statsVehicles' && stats.list.length > 0 ? (
                            <>
                                <ExportEmail
                                    fencesData={fencesData}
                                    setFencesData={setFencesData}
                                    userData={userData}
                                    devices={devices}
                                    filters={clientFilters}
                                    contacts={contacts}
                                />
                                <DropdownButton
                                    drop="up"
                                    variant="light"
                                    className="mx-1 my-2"
                                    title={
                                        <div className="my-2">
                                            <LuDownload className='fs-5' />
                                        </div>
                                    }
                                ><div className="d-flex flex-column">
                                        <Excel
                                            excelData={getRowsStatsVehicles(stats, devices, true, getColumns(columnsStatsVehicles ? columnsStatsVehicles : columnsStatsVehiclesDefault), user.currency, drivers)}
                                            fileName={'statsVehicles'}
                                            date={date}
                                            clientName={costumer}
                                            exportCsv={false}
                                            classStyle={'mx-1 my-1 text-start'}
                                            buttonText={'Excel'} />
                                        <Excel
                                            excelData={getRowsStatsVehicles(stats, devices, true, getColumns(columnsStatsVehicles ? columnsStatsVehicles : columnsStatsVehiclesDefault), user.currency, drivers)}
                                            fileName={'statsVehicles'}
                                            date={date}
                                            clientName={costumer}
                                            exportCsv={true}
                                            classStyle={'mx-1 my-1 text-start'}
                                            buttonText={'CSV'} />
                                        {isClient('ceskyRozhlas', costumer) ? (<>
                                            <Excel
                                                excelData={CRoAddRows.rowCsv ? CRoAddRows.rowCsv : getRowsStatsVehicles({ ...stats, list: stats.list.filter(d => d.distp > 0) }, devices, true, getColumns(columnsCRoCsv), user.currency, drivers)}
                                                fileName={'statsVehicles'}
                                                date={date}
                                                clientName={costumer}
                                                exportCsv={true}
                                                buttonText={'ČRo - CSV'}
                                                classStyle={'mx-1 my-1 text-start'} />
                                            <Pdf
                                                data={CRoAddRows.rowPdf ? CRoAddRows.rowPdf : getRowsStatsVehicles({ ...stats, list: stats.list.filter(d => d.distp > 0) }, devices, true, getColumns(columnsCRoPdf), user.currency, drivers)}
                                                fileName={'statsCro'}
                                                date={date}
                                                devFocus={devFocus}
                                                clientName={costumer}
                                                buttonText={'ČRo - PDF'}
                                                classStyle={'mx-1 my-1 text-start'} />
                                            <Button
                                                className={`mx-1 my-1 ${CRoAddRows.rowCsv ? 'd-none' : ''}`}
                                                variant="light"
                                                onClick={() => {
                                                    setCRoAddRows({ modal: true })
                                                    console.log(getRowsStatsVehicles({ ...stats, list: stats.list.filter(d => d.distp > 0) }, devices, true, getColumns(columnsCRoPdf), user.currency, drivers));
                                                }}>Vložit řádek</Button>
                                            <Modal centered show={CRoAddRows.modal} onHide={() => { setCRoAddRows({ ...CRoAddRows, modal: false }) }} >
                                                <Modal.Header closeButton >
                                                    <Modal.Title>Vložit řádek</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        const newRowCsv = {
                                                            'Osobní číslo řidiče': e.target[0].value,
                                                            'Řidič u vozidla': e.target[1].value,
                                                            'Třída': e.target[2].value,
                                                            'Cena za soukromé jízdy': e.target[3].value,
                                                        }
                                                        const newRowPdf = {
                                                            'Osobní číslo řidiče': e.target[0].value,
                                                            'Řidič u vozidla': e.target[1].value,
                                                            'Třída': e.target[2].value,
                                                            'Cena za soukromé jízdy': e.target[3].value,
                                                            'SPZ': e.target[4].value,
                                                            'Vzdálenost soukromě': e.target[5].value + ' km',
                                                            'Průměrná spotřeba': e.target[6].value + ' l/100km',
                                                            'Palivo průměrná cena za litr': e.target[7].value + ' CZK'
                                                        }
                                                        const nowStatsCsv = getRowsStatsVehicles({ ...stats, list: stats.list.filter(d => d.distp > 0) }, devices, true, getColumns(columnsCRoCsv), user.currency, drivers);
                                                        const nowStatsPdf = getRowsStatsVehicles({ ...stats, list: stats.list.filter(d => d.distp > 0) }, devices, true, getColumns(columnsCRoPdf), user.currency, drivers);
                                                        setCRoAddRows({ modal: false, rowCsv: [...nowStatsCsv, newRowCsv], rowPdf: [...nowStatsPdf, newRowPdf] })
                                                    }}>
                                                        <InputGroup className="w-100  my-3" >
                                                            <FloatingLabel label={'Osobní číslo'} >
                                                                <Form.Control type="text" placeholder="Osobní číslo" required />
                                                            </FloatingLabel>
                                                        </InputGroup>
                                                        <InputGroup className="w-100  my-3" >
                                                            <FloatingLabel label={'Řidič'} >
                                                                <Form.Control type="text" placeholder="Řidič" required />
                                                            </FloatingLabel>
                                                        </InputGroup>
                                                        <InputGroup className="w-100  my-3" >
                                                            <FloatingLabel label={'Středisko'} >
                                                                <Form.Control type="text" placeholder="Středisko" required />
                                                            </FloatingLabel>
                                                        </InputGroup>
                                                        <InputGroup className="w-100  my-3" >
                                                            <FloatingLabel label={'Částka'} >
                                                                <Form.Control type="text" placeholder="Částka" required />
                                                            </FloatingLabel>
                                                        </InputGroup>
                                                        <InputGroup className="w-100  my-3" >
                                                            <FloatingLabel label={'SPZ'} >
                                                                <Form.Control type="text" placeholder="SPZ" required />
                                                            </FloatingLabel>
                                                        </InputGroup>
                                                        <InputGroup className="w-100  my-3" >
                                                            <FloatingLabel label={'Kilometry'} >
                                                                <Form.Control type="text" placeholder="Kilometry" required />
                                                            </FloatingLabel>
                                                        </InputGroup>
                                                        <InputGroup className="w-100  my-3" >
                                                            <FloatingLabel label={'Průměrná spotřeba'} >
                                                                <Form.Control type="text" placeholder="Průměrná spotřeba" required />
                                                            </FloatingLabel>
                                                        </InputGroup>
                                                        <InputGroup className="w-100  my-3" >
                                                            <FloatingLabel label={'Cena za litr'} >
                                                                <Form.Control type="text" placeholder="Cena za litr" required />
                                                            </FloatingLabel>
                                                        </InputGroup>
                                                        <Button type="submit">Potvrdit</Button>
                                                    </Form>
                                                </Modal.Body>
                                            </Modal>
                                        </>) : ''}
                                    </div>
                                </DropdownButton>
                            </>
                        ) : ""}
                        {date && driverCheck && statsType.value === 'driverCheck' && driverCheck.list.length > 0 ? (
                            <>
                                <Excel
                                    excelData={getRowsDriverCheck(driverCheck, true, getColumns(columnsDriverCheck ? columnsDriverCheck : columnsDriverCheckDefault), true)}
                                    fileName={'DriverCheck'}
                                    date={date}
                                    clientName={costumer} />
                            </>

                        ) : ""}
                        {date && driverCheck && statsType.value === 'driverCheck2' && driverCheck.list.length > 0 ? (
                            <>
                                <Excel
                                    excelData={getRowsDriverCheck(driverCheck, true, getColumns(columnsDriverCheck2 ? columnsDriverCheck2 : columnsDriverCheck2Default), false)}
                                    fileName={'DriverCheck2'}
                                    date={date}
                                    clientName={costumer} />
                            </>

                        ) : ""}
                        {date && report && statsType.value === 'sensorsReport' && report.list.length > 0 ? <>
                            {!graph ? <Excel
                                excelData={getRowsSensorsReport(report, true, getColumns(columnsSensorsReport ? columnsSensorsReport : columnsSensorsReportDefault, report))}
                                fileName={'sensorsReport'}
                                date={date}
                                devFocus={devFocus}
                                grid={grid}
                                loading={loading}
                                setLoading={setLoading}
                                report={report}
                                setPageSize={setPageSize}
                                clientName={costumer}
                            /> : <Pdf
                                setExportGraph={setExportGraph}
                                exportGraph={exportGraph}
                                fileName={'sensorsReport'}
                                date={date}
                                devFocus={devFocus}
                                clientName={costumer}
                            />}

                        </> : ""}

                        {date && costumerReportData && statsType.value === 'costumerReport' ? (
                            <>
                                <Button className='ms-2 my-2' variant="light"
                                    onClick={() => { WsSend({ method: 'getExportNode', oid: 29, type: costumerReport.current.type, device: costumerReport.current.device, stampFrom: date[0].toDate(), stampTo: date[1].toDate(), download: 'true' }) }}>
                                    <SiMicrosoftexcel color='green' />
                                </Button>
                            </>
                        ) : ""}

                        {date && statsSelection && statsType.value === 'statsSelection' && statsSelection.list.length > 0 ? (
                            <>
                                <DropdownButton
                                    drop="up"
                                    variant="light"
                                    className="mx-1 my-2"
                                    title={
                                        <div className="my-2">
                                            <LuDownload className='fs-5' />
                                        </div>
                                    }
                                ><div className="d-flex flex-column">
                                        <Excel
                                            excelData={getRowsStatsSelection(statsSelection, devices, true, getColumns(columnsStatsSelection ? columnsStatsSelection : columnsStatsSelectionDefault))}
                                            fileName={'statsSelection'}
                                            date={date}
                                            clientName={costumer}
                                            exportCsv={false}
                                            classStyle={'mx-1 my-1 text-start'}
                                            buttonText={'Excel'}
                                            drivFocus={filterDrivers}
                                            devFocus={filterDevice}
                                            grid={statsFilter.groupDay}
                                            loading={loading}
                                            setLoading={setLoading}
                                            report={statsSelection}
                                            setPageSize={setPageSize}
                                        />

                                        <Excel
                                            excelData={getRowsStatsSelection(statsSelection, devices, true, getColumns(columnsStatsSelection ? columnsStatsSelection : columnsStatsSelectionDefault))}
                                            fileName={'statsSelection'}
                                            date={date}
                                            clientName={costumer}
                                            exportCsv={true}
                                            classStyle={'mx-1 my-1 text-start'}
                                            buttonText={'CSV'} />
                                    </div>
                                </DropdownButton>
                            </>
                        ) : ""}
                    </Suspense>

                </div>
                <Suspense >
                    {statsType.value === 'statsVehicles' ? (
                        <StatsVehicles
                            devices={devices}
                            stats={stats}
                            filterDevice={filterDevice}
                            setFilterDevice={setFilterDevice}
                            filterDrivers={filterDrivers}
                            date={date}
                            setDate={setDate}
                            height={heightWindow - 24}
                            max={true}
                            drivers={drivers}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    ) : ""}
                    {statsType.value === 'statsSelection' ? (
                        <StatsSelection
                            devices={devices}
                            statsSelection={statsSelection}
                            filterDevice={filterDevice}
                            setFilterDevice={setFilterDevice}
                            filterDrivers={filterDrivers}
                            date={date}
                            setDate={setDate}
                            height={heightWindow - 24}
                            max={true}
                            drivers={drivers}
                            statsFilter={statsFilter}
                            loading={loading}
                            setLoading={setLoading}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                        />
                    ) : ""}
                    {statsType.value === 'driverCheck' ? (
                        <DriverCheck
                            driverCheck={driverCheck}
                            filterDevice={filterDevice}
                            setFilterDevice={setFilterDevice}
                            filterDrivers={filterDrivers}
                            date={date}
                            setDate={setDate}
                            height={heightWindow - 24}
                            max={true}
                            typeTruck={true}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    ) : ""}
                    {statsType.value === 'driverCheck2' ? (
                        <DriverCheck
                            driverCheck={driverCheck}
                            filterDevice={filterDevice}
                            setFilterDevice={setFilterDevice}
                            filterDrivers={filterDrivers}
                            date={date}
                            setDate={setDate}
                            height={heightWindow - 24}
                            max={true}
                            typeTruck={false}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    ) : ""}
                    {statsType.value === 'sensorsReport' ? (
                        <SensorsReport
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            report={report}
                            setReport={setReport}
                            devFocus={devFocus}
                            setDevFocus={setDevFocus}
                            date={date}
                            setDate={setDate}
                            height={heightWindow - 24}
                            max={true}
                            grid={grid}
                            setGrid={setGrid}
                            loading={loading}
                            setLoading={setLoading}
                            graph={graph}
                            sensors={filterSensors}
                            setExportGraph={setExportGraph}
                            exportGraph={exportGraph}
                        />
                    ) : ""}
                    {statsType.value === 'costumerReport' ? (
                        <CostumerReport
                            costumer={costumerReport.current}
                            data={costumerReportData}
                            date={date}
                            setDate={setDate}
                            height={heightWindow - 24}
                        />
                    ) : ""}
                </Suspense>
            </div>

        </div >
    )
}