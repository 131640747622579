
import React, { useState } from 'react';
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
// import { Container } from 'react-bootstrap';

import "./App.scss";
import { SetPage } from './scripts/ws';
import { Login, Overview, Rides, Mapview, Tacho, Reports, Profile, Config, Equipments, Dashboards, Fences, CarRental, Time } from './pages';
import { UserProvider, NavBar, isBrand } from './components';

export default function App() {
  const [login, setLogin] = useState();
  const [widgetOnDrop, setWidgetOnDrop] = useState();
  const [defaultLayout, setDefaultLayout] = useState();
  const location = useLocation();
  // master manualy render
  const [, forceUpdate] = useState();

  if (isBrand('alfakomtemp')) import('./brand/alfakomtemp/style.css');
  else if (isBrand('globalsec')) import('./brand/globalsec/style.css');

  if (!login) return <UserProvider> <Login setLogin={setLogin} /></UserProvider>
  //&& location.pathname !== '/api'
  // if (location.pathname === '/api') return (
  //   <UserProvider>
  //     <Api />
  //   </UserProvider>)
  // if (login && location.pathname === '/') return <UserProvider><Navigate to={"/overview"} replace={true} /></UserProvider>

  SetPage(location.pathname)

  return (
      <UserProvider>
        <div className="main-wrapper">
          <NavBar setLogin={setLogin} setWidgetOnDrop={setWidgetOnDrop} setDefaultLayout={setDefaultLayout} />
          <main className="main-container bg-white">
            <Routes>
              <Route exact path="/overview" element={<Overview forceUpdate={forceUpdate} widgetOnDrop={widgetOnDrop} setWidgetOnDrop={setWidgetOnDrop} defaultLayout={defaultLayout} />} />
              <Route exact path="/rides" element={<Rides forceUpdate={forceUpdate} widgetOnDrop={widgetOnDrop} setWidgetOnDrop={setWidgetOnDrop} defaultLayout={defaultLayout} />} />
              <Route exact path="/reports" element={<Reports forceUpdate={forceUpdate} widgetOnDrop={widgetOnDrop} setWidgetOnDrop={setWidgetOnDrop} defaultLayout={defaultLayout} />} />
              <Route exact path="/tacho" element={<Tacho forceUpdate={forceUpdate} widgetOnDrop={widgetOnDrop} setWidgetOnDrop={setWidgetOnDrop} defaultLayout={defaultLayout} />} />
              {/* <Route exact path="/map" element={<Mapview />} /> */}
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/config" element={<Config />} />
              <Route exact path="/config/equipments" element={<Equipments />} />
              <Route exact path="/config/dashboards" element={<Dashboards />} />
              <Route exact path="/config/fences" element={<Fences />} />
              <Route exact path="/autopujcovna" element={<CarRental />} />
              <Route exact path="time" element={<Time />} />
              {/* <Route path="*" element={< />} /> */}
            </Routes>
          </main>
        </div>
      </UserProvider>
  );
}