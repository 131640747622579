import React, { useState, useContext, useEffect, useRef, Suspense } from "react";
import GridTable from '@nadavshaar/react-grid-table';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Form, Button, FloatingLabel, InputGroup, Spinner } from "react-bootstrap";
import { ToastContainer, toast, Slide } from 'react-toastify';

import { UserContext } from "../../components";
import { client } from 'websocket';
import { WsSend, WsAnal, WSdata, WSdataList } from "../../scripts/ws";
import { iconStatus, statusstamp, statusstampSort, getLabel } from '../../components/DevicesView/columnsDevicesView'
import { useWindowSize } from '../../components'
import Loading from "../../components/Loading";

import { IoMdArrowRoundBack } from "react-icons/io";
import { AiOutlineSearch } from 'react-icons/ai'
import { FaCopy, } from 'react-icons/fa'

import 'react-toastify/dist/ReactToastify.css';

const Excel = React.lazy(() => import('../../components/export').then(module => ({ default: module.Excel })));

export default function Equipments() {
    const { t, i18n } = useTranslation('translation');

    document.title = i18n.t('widget.equipments')

    const [devices, setDevices] = useState(false);
    const [equipmentPopup, setEquipmentPopup] = useState(null);
    const [loadingSetDevice, setLoadingSetDevice] = useState(null);
    const editData = useRef(null);
    const refWS4 = useRef(null);
    const loading = useRef(false);
    const reactGridRef = useRef(null);

    const { setDev, setListClientSearch, listClientSearch, clientGroups, clientRoles, dev, costumer } = useContext(UserContext);
    const height = useWindowSize().height;

    if (!devices && !refWS4.current) {
        refWS4.current = true;
        loading.current = true;
        WsSend({ method: 'getDevices', oid: 4, restriction: 'configurator' })
    }

    const masterUser = ('iscurator' || 'dealer' || 'resellers')

    client.onmessage = (message) => {
        let data = WsAnal(message)
        // console.log('new data', data);

        if (data) {
            // update message
            if (data.length > 0 && devices) {
                setDevices(WSdata(data, devices, 4));
                setListClientSearch(WSdataList(data, listClientSearch, 11));
            }

            // first new message from query
            else {
                if (data) {
                    if (data.id === 4) {
                        const res = WSdata(data, devices, 4).list;
                        setDevices(res);
                        setDev(res);
                        loading.current = false;
                    }
                    if (data.oid === 14 && data.value) {
                        setEquipmentPopup(false)
                        setLoadingSetDevice(false)
                        setTimeout(() => {
                            toast.success(t('toastSuccess'))
                        }, 200);
                    }
                    if (data.id === 11) setListClientSearch(WSdata(data, null, 11));
                }

            }
        }
    }



    const clickOpenFota = ({ value, data }) => {
        return (
            <div className={'rgt-cell-inner rgt-cell-component'} onClick={() => { window.open('https://fm.teltonika.lt/devices?query=' + data.imei, '_blank') }} >
                <div className='rgt-text-truncate copy'>
                    {value}
                </div>
            </div>
        )
    }

    const clickCopy = ({ value, data }) => {
        return (
            <div className={'rgt-cell-inner rgt-cell-component'} onDoubleClick={(() => { })} onClick={() => { navigator.clipboard.writeText(value); notify(value + t('page.copy')) }} >
                <div className='rgt-text-truncate copy'>
                    {value}
                </div>
            </div>
        )
    }

    const clickCopyImei = ({ value, data }) => {

        return (
            <div className={`rgt-cell-inner rgt-cell-component ${!value ? 'withoutImei' : ''}`} onDoubleClick={(() => { })} onClick={() => { navigator.clipboard.writeText(value); notify(value + t('page.copy')) }} >
                <div className='rgt-text-truncate copy'>
                    {value}
                </div>
            </div>
        )
    }

    const openSetting = ({ value, data, tableManager }) => {

        setTimeout(() => {
            if (tableManager && sessionStorage.getItem("d-search") && sessionStorage.getItem("d-search").length > 2) {
                const { searchApi } = tableManager;
                const { setSearchText } = searchApi;
                setSearchText(sessionStorage.getItem("d-search") ? sessionStorage.getItem("d-search") : '')
            }
        }, 500);

        return (
            <div className={'rgt-cell-inner rgt-cell-component'} onClick={() => { setEquipmentPopup(data) }} >
                <div className='rgt-text-truncate click'>
                    {value}
                </div>
            </div>
        )
    }

    const columnsEquipments = [
        {
            id: 0,
            index: 0,
            field: 'index',
            width: '70px',
            label: ' ',
            visible: true,
            cellRenderer: openSetting,
            sortable: false,
        },
        {
            id: 1,
            index: 1,
            field: 'status',
            width: '115px',
            label: getLabel('status'),
            visible: true,
            sortable: false,
            cellRenderer: iconStatus,
        },
        {
            id: 2,
            index: 2,
            field: 'name',
            width: 'auto',
            label: getLabel('name'),
            cellRenderer: openSetting,
            visible: true,
            sortable: true,
        },
        {
            id: 3,
            index: 3,
            field: 'plate',
            width: 'auto',
            label: getLabel('plate'),
            cellRenderer: openSetting,
            visible: true,
            sortable: true,
        },
        {
            id: 4,
            index: 4,
            field: 'type',
            width: 'auto',
            label: 'Type',
            cellRenderer: clickOpenFota,
            visible: clientGroups.includes(masterUser),
            sortable: true,
        },
        {
            id: 5,
            index: 5,
            field: 'imei',
            width: 'auto',
            label: 'Imei',
            cellRenderer: clickCopyImei,
            visible: clientGroups.includes(masterUser),
            sortable: true,
        },
        {
            id: 6,
            index: 6,
            field: 'sim',
            width: 'auto',
            label: 'SIM',
            cellRenderer: clickCopy,
            visible: clientGroups.includes(masterUser),
            sortable: true,
        },
        {
            id: 7,
            index: 7,
            field: 'statusstamp',
            cellRenderer: statusstamp,
            width: 'auto',
            visible: true,
            label: getLabel('statusstamp'),
            searchable: false,
            sort: statusstampSort,
        },
        {
            id: 8,
            index: 8,
            field: 'id',
            width: 'auto',
            label: 'Id',
            cellRenderer: clickCopy,
            visible: clientGroups.includes(masterUser),
            sortable: false,
        },

    ]

    const getRows = ((d) => {
        if (!d) return null
        // console.log(d);
        return devices.map((d, index) => {
            return ({
                'index': index + 1,
                'id': d.id,
                'status': d.status,
                'icon': d.icon,
                'icolor': d.icolor,
                'name': d.name,
                'type': d.dtype,
                'plate': d.plate,
                'imei': d.code,
                'statusstamp': d.statusstamp,
                'sim': d.sim,
                'vtext': d.vtext,
                'dtext': d.dtext,
                'gitem1': d.gitem1
            })
        })
    })

    const handleOnChange = (value, type) => {
        if (type === 'name') editData.current = { ...editData.current, name: value }
        if (type === 'plate') editData.current = { ...editData.current, plate: value }
        if (type === 'vtext') editData.current = { ...editData.current, vtext: value }
        if (type === 'dtext') editData.current = { ...editData.current, dtext: value }
    }

    const notify = (text) => toast.success(text);

    useEffect(() => {
        //delete all if switch costumer
        console.log(devices, refWS4.current);
        if (!dev && refWS4.current) {
            refWS4.current = false;
            setDevices(dev);
            console.log('costumer change delete data from equipments');
        }
    }, [costumer])

    if (reactGridRef.current) {
        const withoutImei = reactGridRef.current.querySelectorAll('.withoutImei');
        if (withoutImei) {
            withoutImei.forEach(w => {
                const dataRowIndex = w.parentElement.getAttribute('data-row-index');
                const elements = reactGridRef.current.querySelectorAll(`[data-row-index="${dataRowIndex}"]`);
                elements.forEach(element => {
                    element.classList.add('deactivatedDevice');
                });
            })

        }
    }

    if (devices && devices.length > 0) return (
        <div className="config" style={{ minHeight: '100vh' }}>
            <div className='gridHandle d-flex justify-conten-evenly'>
                <div className="back">
                    <Link to="/config">
                        <IoMdArrowRoundBack className="fs-5 mx-2 text-secondary my-1 ms-2" />
                    </Link>
                </div>
                <span className={`ms-2 gridHandleOn`}>{t('widget.equipments')}</span>
                <span className="m-auto">  </span>
                <Suspense >
                    <span style={{ position: 'fixed', right: '15%', paddingRight: '70px' }}>
                        <Excel
                            excelData={getRows(devices)}
                            fileName={'devices'}
                            clientName={costumer}
                            exportCsv={true}
                            classStyle={'mx-1 p-0 px-1'} />
                        <Excel
                            excelData={getRows(devices)}
                            fileName={'devices'}
                            clientName={costumer}
                            exportCsv={false}
                            classStyle={'mx-1 p-0 px-1'} />
                    </span>
                </Suspense >
            </div>
            <div className="d-flex justify-content-center" ref={reactGridRef}>
                <GridTable
                    style={{ minHeight: 'unset', height: (height - 55) + 'px' }}
                    className={'devices-table'}
                    columns={columnsEquipments}
                    rows={getRows(devices)}
                    showSearch={true}
                    showColumnVisibilityManager={false}
                    showRowsInformation={false}
                    isPaginated={false}
                    texts={{
                        search: '',
                        noResults: t('devices.notFind')
                    }}
                    icons={{ search: <AiOutlineSearch /> }}
                    highlightSearch={true}
                    onRowClick={({ data }) => {
                        // setEquipmentPopup(data)
                    }}
                    onSearchTextChange={(searchText) => { sessionStorage.setItem("d-search", searchText) }}
                />
            </div>
            {equipmentPopup ? (
                <Modal size='lg' fullscreen='sm-down' centered show={equipmentPopup} onHide={() => { setEquipmentPopup(false); editData.current = false; }} >
                    <Modal.Header closeButton >
                        <Modal.Title>{t('devices.edit')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            // console.log(editData);
                            if (editData.current) {
                                setLoadingSetDevice(true)
                                const params = {
                                    method: 'setDevice',
                                    id: equipmentPopup.id,
                                    oid: 14
                                }
                                if (editData.current.name) params.name = editData.current.name;
                                if (editData.current.plate) params.plate = editData.current.plate;
                                if (editData.current.vtext) params.vtext = editData.current.vtext;
                                editData.current = false;
                                WsSend(params)
                            }
                        }}>
                            <div className="d-flex flex-column align-items-center">
                                <FloatingLabel className="w-75 max-w-50vh my-2" label={t('devices.name')}>
                                    <Form.Control type="text" defaultValue={equipmentPopup.name} onChange={(e) => { handleOnChange(e.target.value, 'name') }} />
                                </FloatingLabel>
                                <FloatingLabel className="w-75 max-w-50vh my-2" label={t('devices.plate')}>
                                    <Form.Control type="text" defaultValue={equipmentPopup.plate} onChange={(e) => { handleOnChange(e.target.value, 'plate') }} />
                                </FloatingLabel>
                                <FloatingLabel className="w-75 max-w-50vh my-2" label={t('devices.note')}>
                                    <Form.Control className="mb-3" defaultValue={equipmentPopup.vtext} onChange={(e) => { handleOnChange(e.target.value, 'vtext') }} />
                                </FloatingLabel>
                                <div className="w-100 border border-light"></div>
                                {clientGroups.includes(masterUser) ? <FloatingLabel className="w-75 max-w-50vh my-2 mt-3" label={t('devices.note_master')}>
                                    <Form.Control className="mb-3" as="textarea" rows={3} defaultValue={equipmentPopup.dtext} onChange={(e) => { handleOnChange(e.target.value, 'dtext') }} />
                                </FloatingLabel> : ''}

                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <Button className={loadingSetDevice ? "w2-5" : "w-50 " + "max-w-50vh mb-3 my-2 btn btn-dark"} type="submit" disabled={false}>{
                                    loadingSetDevice ? <Spinner animation="border" role="status"><span className="visually-hidden">{t('loading')}</span></Spinner> : t('save')
                                }</Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>) : ''}
            <ToastContainer
                position="bottom-center"
                autoClose={1500}
                pauseOnHover={false}
                theme="light"
                transition={Slide}
                closeOnClick
                hideProgressBar
            />
        </div>
    )
    else if (loading.current) return <Loading color={getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')} />
    else return <div className="d-flex justify-content-center align-items-center min-vh-100 fs-2 fw-bold text-secondary">{i18n.t('devices.notFind')}</div>
}